import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { PageViewMode } from "../../store/models/PageViewMode";
import MenuLayout from "../app/MenuLayout";
import { useTranslation } from "react-i18next";
import SegmentForm from "./SegmentForm";
import { SegmentDto } from "../../store/models/segments/SegmentDto";
import { Api } from "../../api/Api";
import { useEffect, useState } from "react";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";

const emptySegment = {} as SegmentDto;

const SegmentFormPage = (props: ThunkProps) => {
  const { viewMode, id } = useParams<"viewMode" | "id">();
  const [segment, setSegment] = useState<SegmentDto | null>(null);
  const { t } = useTranslation();
  const { thunkCreateErrorNotification } = props;

  useEffect(() => {
    if (id) {
      Api.getSegmentByExternalId(id)
        .then((response) => {
          setSegment(response);
        })
        .catch((error) => {
          thunkCreateErrorNotification(error.message, error);
        });
    }
  }, [id, thunkCreateErrorNotification]);

  function getHeadline(id?: string, viewMode?: string): string {
    if (!id) {
      return t("segmentFormPage.create");
    }
    if (viewMode === PageViewMode.VIEW) return t("segmentFormPage.show");
    return t("segmentFormPage.edit");
  }

  return (
    <MenuLayout headline={getHeadline(id, viewMode)}>
      <SegmentForm segment={segment || emptySegment} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkCreateErrorNotification }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(SegmentFormPage);
