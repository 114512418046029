import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UrlPathSegmentBuilder } from "../../api/url";
import { canReadSegments } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import UnauthorizedPage from "../app/UnauthorizePage";
import SegmentSearch, { SegmentSearchCondition } from "./SegmentSearch";
import SegmentsTable from "./SegmentsTable";

const SegmentsPage = () => {
  const { t } = useTranslation();
  const [segmentSearchCondition, setSegmentSearchCondition] = useState<SegmentSearchCondition>({});

  if (!canReadSegments()) {
    return <UnauthorizedPage />;
  }

  return (
    <MenuLayout headline={t("segmentsPage.pageTitle")}>
      <Grid container spacing={2}>
        {canReadSegments() && (
          <Grid container item direction="column" alignItems="flex-end" spacing={2}>
            <Grid item>
              <Button component={Link} to={UrlPathSegmentBuilder} variant="contained" color="primary">
                {t("segmentsPage.buttonSegmentBuilder")}
              </Button>
            </Grid>
          </Grid>
        )}

        <Grid item md={12}>
          <SegmentSearch setSearchCondition={setSegmentSearchCondition} />
        </Grid>

        <Grid item md={12}>
          <SegmentsTable searchCondition={segmentSearchCondition} />
        </Grid>
      </Grid>
    </MenuLayout>
  );
};

export default SegmentsPage;