import { format } from "date-fns";
import { de } from "date-fns/locale";
import { Api } from "../../api/Api";
import { getView } from "../../api/SnowflakeApi";
import { nameof } from "../../components/atoms/Utils";
import { BooleanThunk } from "../IStore";
import {} from "../models/customer/CustomerStatusDto";
import { CustomerActivityDto } from "../models/dashboard/CustomerActivityDto";
import { CustomerActivityYearDto } from "../models/dashboard/CustomerActivityYearDto";
import { CustomerRegistrationDto } from "../models/dashboard/CustomerRegistrationDto";
import { CustomerStatusDto } from "../models/dashboard/CustomerStatusDto";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const GET_DASHBOARD_INTERACTION_KPI_DAY = "GET_DASHBOARD_INTERACTION_KPI_DAY";
export const GET_CUSTOMER_REGISTRATIONS = "GET_CUSTOMER_REGISTRATIONS";
export const GET_CUSTOMER_STATUSES = "GET_CUSTOMER_STATUSES";
export const GET_CUSTOMER_ACTIVITIES = "GET_CUSTOMER_ACTIVITIES";
export const GET_CUSTOMER_ACTIVITIES_YEAR = "GET_CUSTOMER_ACTIVITIES_YEAR";

function getDate(datetime: Date) {
    const offset = datetime.getTimezoneOffset();
    datetime = new Date(datetime.getTime() - (offset*60*1000));
    return datetime.toISOString().split('T')[0];
}

export const thunkGetDashboardKpis =
  (dateObject: Date): BooleanThunk =>
  async (dispatch) => {
    try {
      const date = getDate(dateObject);
      const payload = await Api.getDashboardKpis(date);
      // UGLY hack to shift time to timezone
      payload.kpiPerHour = payload.kpiPerHour
        .map((kpi) => ({
          ...kpi,
          hour: (kpi.hour - dateObject.getTimezoneOffset() / 60 + 24) % 24,
        }))
        .sort((kpi1, kp2) => kpi1.hour - kp2.hour);
      dispatch({
        type: GET_DASHBOARD_INTERACTION_KPI_DAY,
        payload,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load bonus statistics", e));
      return false;
    }
  };

export const thunkGetCustomerRegistrations =
  (offset?: number): BooleanThunk =>
  async (dispatch) => {
    try {
      const payload = await getView<CustomerRegistrationDto>(
        "V_APPL_DSH_CUSTOMER_REGISTRATION",
        offset,
        nameof<CustomerRegistrationDto>("REGISTRATION_TS"),
        "desc"
      );
      // UGLY hack to sort registrations and remove 1900
      payload.data = payload.data
        .filter((cr) => cr.REGISTRATION_TS !== "1900-01-01T00:00:00.000000000+01:00")
        .sort((cr1, cr2) => cr1.REGISTRATION_TS.localeCompare(cr2.REGISTRATION_TS));
      dispatch({
        type: GET_CUSTOMER_REGISTRATIONS,
        payload,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load customer registration statistics", e));
      return false;
    }
  };

export const thunkGetCustomerStatuses =
  (offset?: number): BooleanThunk =>
  async (dispatch) => {
    try {
      const payload = await getView<CustomerStatusDto>(
        "V_APPL_DSH_CUSTOMER_STATUS",
        offset,
        nameof<CustomerStatusDto>("DWH_INSERT_DATE"),
        "desc"
      );
      // UGLY hack to fix date and sorting
      payload.data = payload.data
        .map((cs) => ({
          ...cs,
          DWH_INSERT_DATE: new Date(Number(cs.DWH_INSERT_DATE) * 24 * 60 * 60 * 1000).toISOString(),
        }))
        .sort((cr1, cr2) => cr1.DWH_INSERT_DATE.localeCompare(cr2.DWH_INSERT_DATE));

      dispatch({
        type: GET_CUSTOMER_STATUSES,
        payload,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load customer status statistics", e));
      return false;
    }
  };

export const thunkGetCustomerActivities =
  (offset?: number): BooleanThunk =>
  async (dispatch) => {
    try {
      const payload = await getView<CustomerActivityDto>(
        "V_APPL_DSH_CUSTOMER_ACTIVITY_AGG",
        offset,
        nameof<CustomerActivityDto>("MONTH_LAST_DATE"),
        "desc"
      );

      // Convert from unix timestamp to month name
      payload.data = payload.data
        .map((ca) => ({
          ...ca,
          monthLabel: formatMonthLabel(ca.MONTH_LAST_DATE),
        }))
        .sort((ca1, ca2) => ca1.MONTH_LAST_DATE.localeCompare(ca2.MONTH_LAST_DATE));
      dispatch({
        type: GET_CUSTOMER_ACTIVITIES,
        payload,
      });
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Failed to load customer activity statistics", e));

      return false;
    }
  };

export const thunkGetCustomerActivitiesYear = (): BooleanThunk => async (dispatch) => {
  try {
    const payload = await getView<CustomerActivityYearDto>("V_APPL_DSH_CUSTOMER_ACTIVITY_YEAR");

    dispatch({
      type: GET_CUSTOMER_ACTIVITIES_YEAR,
      payload,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Failed to load customer activity metrics", e));

    return false;
  }
};

const formatMonthLabel = (unitTimestamp: string) =>
  format(new Date(Number(unitTimestamp) * 1000), "MM/yy", { locale: de });
