import { CloudUpload } from "@mui/icons-material";
import { Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ImsPaperHead from "../../../atoms/ImsPaperHead";

interface Step2Props {
  onValidationChange: (isValid: boolean) => void;
  onFileSelect: (file: File | null) => void;
  initialFile?: File;
  onBack: () => void;
  onNext: () => void;
}

const Step2 = ({ onValidationChange, onFileSelect, initialFile, onBack, onNext }: Step2Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(initialFile || null);
  const [error, setError] = useState<string | null>(null);

  const description = t("segmentBuilder.csv.step2.description").split("\n\n");

  useEffect(() => {
    onValidationChange(selectedFile !== null);
  }, [selectedFile, onValidationChange]);

  const validateCSV = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = e.target?.result as string;
          const lines = content.split('\n').filter(line => line.trim());
          
          if (lines.length === 0) {
            setError(t("segmentBuilder.csv.step2.emptyFile"));
            setSelectedFile(null);
            onFileSelect(null);
            resolve(false);
            return;
          }

          // Check if all lines have exactly one column
          const hasMultipleColumns = lines.some(line => {
            const columns = line.split(',').filter(col => col.trim());
            return columns.length !== 1;
          });

          if (hasMultipleColumns) {
            setError(t("segmentBuilder.csv.step2.multipleColumns"));
            setSelectedFile(null);
            onFileSelect(null);
            resolve(false);
            return;
          }

          setError(null);
          resolve(true);
        } catch (err) {
          setError(t("segmentBuilder.csv.step2.invalidFormat"));
          setSelectedFile(null);
          onFileSelect(null);
          resolve(false);
        }
      };
      reader.readAsText(file);
    });
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setError(null);

    if (file) {
      if (file.type === "text/csv" || file.name.endsWith(".csv")) {
        const isValid = await validateCSV(file);
        if (isValid) {
          setSelectedFile(file);
          onFileSelect(file);
        } else {
          // Reset file input
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }
      } else {
        // Reset file input
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setError(t("segmentBuilder.csv.step2.onlyCsvAllowed"));
        setSelectedFile(null);
        onFileSelect(null);
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <Paper style={{ padding: theme.spacing(3), marginTop: theme.spacing(6) }}>
        <ImsPaperHead text={t("segmentBuilder.csv.step2.title")} />
        <Grid container spacing={4} alignItems="center">
          <Grid item sm={12}>
            <Typography variant="body1" style={{ marginBottom: theme.spacing(2) }}>
              {description[0]}
            </Typography>
            <Typography variant="body1">
              {description[1]}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ marginTop: theme.spacing(4) }}>
          <Grid item sm={12}>
            <input
              type="file"
              accept=".csv"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileSelect}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
              onClick={handleUploadClick}
            >
              {t("segmentBuilder.csv.step2.uploadFile")}
            </Button>
          </Grid>
          <Grid item sm={12}>
            <Typography
              variant="body2"
              color={error ? "error" : "textSecondary"}
            >
              {error || (selectedFile ? selectedFile.name : t("segmentBuilder.csv.step2.noFileUploaded"))}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          color="secondary"
          variant="contained"
          style={{ margin: theme.spacing(2, 0, 2, 0), minWidth: 150 }}
          onClick={onBack}
        >
          {t("common.back")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: theme.spacing(2, 0, 2, 0), minWidth: 150 }}
          onClick={onNext}
          disabled={!selectedFile}
        >
          {t("common.next")}
        </Button>
      </div>
    </>
  );
};

export default Step2;
