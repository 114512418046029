import { Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Api } from "../../api/Api";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";
import { SegmentDto } from "../../store/models/segments/SegmentDto";
import ImsPaperHead from "../atoms/ImsPaperHead";
import SegmentAssigned from "./SegmentAssigned";

const SegmentOverview = ({ externalCustomerId }: { externalCustomerId: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [assignedSegments, setAssignedSegments] = useState<SegmentDto[]>([]);
  const [allSegments, setAllSegments] = useState<SegmentDto[]>([]);

  useEffect(() => {
    const fetchAssignedSegments = async () => {
      try {
        const segments = await Api.getAllAssignedSegmentsForCustomer(externalCustomerId);
        setAssignedSegments(segments);
      } catch (error) {
        thunkCreateErrorNotification("Failed to fetch assigned segments", error);
      }
    };

    const fetchAllSegments = async () => {
      try {
        const response = await Api.getPaginatedSegments("", 1, 100, "name", "ASC");
        setAllSegments(response.segments);
      } catch (error) {
        thunkCreateErrorNotification("Failed to fetch all segments", error);
      }
    };

    fetchAssignedSegments();
    fetchAllSegments();
  }, [externalCustomerId]);

  const handleSave = (newAssignedSegments: SegmentDto[]) => {
    setAssignedSegments(newAssignedSegments);
    setEditDialogVisible(false);
  };

  const renderSegments = (segments: SegmentDto[]) => {
    return segments.map((segment) => (
      <Grid item key={segment.externalSegmentId} style={{ marginBottom: 8 }}>
        <Paper
          style={{
            padding: 8,
            backgroundColor: "#e0f7fa",
            display: "flex",
            alignItems: "center",
            width: "fit-content",
          }}
        >
          <Typography style={{ marginRight: 4 }}>{segment.name}</Typography>
        </Paper>
      </Grid>
    ));
  };

  return (
    <Grid height={"100%"}>
      <Paper
        style={{
          padding: theme.spacing(3),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <ImsPaperHead text={t("segmentAssigned.title")} />
          <Grid container spacing={2}>
            {renderSegments(assignedSegments)}
          </Grid>
        </div>
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEditDialogVisible(true)}
            style={{ marginTop: 16 }}
          >
            {t("segmentAssigned.editButton")}
          </Button>
        </Grid>
      </Paper>
      {editDialogVisible && (
        <SegmentAssigned
          assignedSegments={assignedSegments}
          allSegments={allSegments}
          externalCustomerId={externalCustomerId}
          onClose={() => setEditDialogVisible(false)}
          onSave={handleSave}
        />
      )}
    </Grid>
  );
};

export default SegmentOverview;
