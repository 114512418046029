import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IStore } from "../../store/IStore";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Button, Container, Paper, Typography, useTheme } from "@mui/material";
import { thunkDownloadFile } from "../../store/actions/DownloadActions";
import NotificationBar from "../notification/NotificationBar";
import { useTranslation } from "react-i18next";

const DownloadFilePage = (props: ThunkProps) => {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const type = new URLSearchParams(location.search).get("type");
  const id = new URLSearchParams(location.search).get("id");
  const fileName = new URLSearchParams(location.search).get("file-name");

  const triggerDownload = useCallback(async () => {
    if (type && id && fileName) {
      const preSignedUrl = await props.thunkDownloadFile(type, id, fileName);
      if (typeof preSignedUrl === "string" && preSignedUrl) {
        const link = document.createElement("a");
        link.href = preSignedUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }, [props, type, id, fileName]);

  useEffect(() => {
    triggerDownload();
  }, [triggerDownload]);

  return (
    <>
      <NotificationBar />
      <Container maxWidth="lg" style={{ textAlign: "center", marginTop: theme.spacing(8) }}>
        <Paper elevation={3} style={{ padding: theme.spacing(6), position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Typography variant="h3" gutterBottom>
            {t("downloadFilePage.title")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("downloadFilePage.message")}
          </Typography>
          <Button variant="contained" color="primary" onClick={triggerDownload} style={{ marginTop: theme.spacing(4) }}>
            {t("downloadFilePage.downloadButton")}
          </Button>
        </Paper>
      </Container>
    </>
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkDownloadFile,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(DownloadFilePage);
