import { Add, Delete, Edit } from "@mui/icons-material";
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import { IStore } from "../../store/IStore";
import {
  BonusRuleDto,
  useRuleFilterApplyTypeOptions,
  RuleFilterCustomerPropertyType,
} from "../../store/models/bonusRule/BonusRuleDto";
import { COLOR_GRAY_1 } from "../atoms/ImsMaterialTheme";
import FilterForm from "./FilterForm";
import { thunkDeleteBonusRuleFilter } from "../../store/actions/BonusRuleActions";
import { BackdropProcessing } from "../app/BackdropProcessing";
import { useTranslation } from "react-i18next";
import KnistrConfirmDialog from "../atoms/KnistrConfirmDialog";

interface FilterProps {
  bonusRule: BonusRuleDto;
  viewMode: boolean;
}

const Filter = (props: FilterProps & ThunkProps) => {
  const theme = useTheme();
  const originalCurrentBonusRule = props.originalBonusRules.find(
    (bonusRule) => bonusRule.ruleNumber === props.bonusRule.ruleNumber
  );
  const isSavedFilter = (filterNumber: string) => {
    const indexOfSavedFilter = !originalCurrentBonusRule
      ? -1
      : originalCurrentBonusRule.ruleFilters.findIndex((f) => f.ruleFilterNumber === filterNumber);
    return indexOfSavedFilter !== -1;
  };

  const addFilter = () => {
    const filterNumber = uuid();
    setEditFilterNumber(filterNumber);
  };
  const deleteFilter = async () => {
    if (!deleteFilterNumber) {
      return;
    }
    setProcessing(true);
    const indexOfExistingFilter = !originalCurrentBonusRule
      ? -1
      : originalCurrentBonusRule.ruleFilters.findIndex((f) => f.ruleFilterNumber === deleteFilterNumber);
    if (indexOfExistingFilter === -1) {
      props.bonusRule.ruleFilters = props.bonusRule.ruleFilters?.filter(
        (filter) => filter.ruleFilterNumber !== deleteFilterNumber
      );
    } else {
      await props.thunkDeleteBonusRuleFilter(deleteFilterNumber);
    }
    setProcessing(false);
  };
  const uploadFilterValues = async (e: ChangeEvent<HTMLInputElement>, ruleFilterNumber: string | undefined) => {
    if (!e.target.files?.length) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
      const records = result
        .toString()
        .replace("\t", "")
        .replace("\r", "")
        .split("\n")
        .filter((item) => !!item && item.trim().length > 0)
        .map((item) => item.trim());
      setInitialRuleFilterValues(records);
      setEditFilterNumber(ruleFilterNumber);
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const [editFilterNumber, setEditFilterNumber] = useState<string>();
  const [deleteFilterNumber, setDeleteFilterNumber] = useState<string>();
  const [initialRuleFilterValues, setInitialRuleFilterValues] = useState<string[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);
  const { t } = useTranslation();
  const options = useRuleFilterApplyTypeOptions();

  return (
    <>
      <BackdropProcessing processing={processing}></BackdropProcessing>
      <Grid item md={12}>
        {t("bonusRuleFilter.itemFilter")}
      </Grid>
      {deleteFilterNumber && (
        <KnistrConfirmDialog
          title={t("bonusRuleFilterDelete.title")}
          description={t("bonusRuleFilterDelete.description", { name: props.bonusRule.ruleName })}
          cancelButton={t("common.cancel")}
          confirmButton={t("common.delete")}
          setConfirmVisible={() => setDeleteFilterNumber(undefined)}
          onConfirm={deleteFilter}
        />
      )}
      {editFilterNumber && (
        <FilterForm
          bonusRule={props.bonusRule}
          editFilterNumber={editFilterNumber}
          setEditFilterNumber={setEditFilterNumber}
          initialRuleFilterValues={initialRuleFilterValues}
        />
      )}
      <Grid item md={2}>
        {t("bonusRuleFilter.item")}
      </Grid>
      {props.bonusRule.ruleFilters && (
        <Grid item md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("bonusRuleFilter.name")}</TableCell>
                <TableCell>{t("bonusRuleFilter.type")}</TableCell>
                <TableCell>{t("bonusRuleFilter.value")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.bonusRule.ruleFilters
                .filter(
                  (ruleFilter) =>
                    ruleFilter.ruleFilterPropertyName !== RuleFilterCustomerPropertyType.CIRCLE_NUMBER &&
                    ruleFilter.ruleFilterPropertyName !== RuleFilterCustomerPropertyType.EXTERNAL_SEGMENT_ID
                )
                .map((filter) => (
                  <TableRow key={filter.ruleFilterNumber}>
                    <TableCell>
                      {
                        props.configGroups
                          .map((configGroup) => configGroup.values)
                          .flat()
                          .find((value) => value.key === filter.ruleFilterPropertyName)?.description
                      }
                    </TableCell>
                    <TableCell>{options.find((type) => filter.ruleFilterApplyType === type.value)?.label}</TableCell>
                    <TableCell>
                      {filter.ruleFilterValues.slice(0, 5).join(", ")}
                      {filter.ruleFilterValues.length > 5 && "..."}
                    </TableCell>
                    {!props.viewMode && (
                      <TableCell>
                        <Button component="label">
                          <Edit
                            style={{
                              marginLeft: theme.spacing(2),
                              fontSize: 24,
                              backgroundColor: theme.palette.primary.main,
                              color: COLOR_GRAY_1,
                              cursor: "hand",
                              borderRadius: "50%",
                              padding: 1,
                            }}
                            onClick={() => {
                              if (!filter.ruleFilterNumber || !isSavedFilter(filter.ruleFilterNumber)) {
                                setEditFilterNumber(filter.ruleFilterNumber);
                              }
                              return false;
                            }}
                          />
                          {filter.ruleFilterNumber && isSavedFilter(filter.ruleFilterNumber) && (
                            <input
                              type="file"
                              accept=".csv"
                              hidden
                              onChange={(e) => uploadFilterValues(e, filter.ruleFilterNumber)}
                            />
                          )}
                        </Button>
                        <Button component="label">
                          <Delete
                            style={{
                              marginLeft: theme.spacing(2),
                              fontSize: 24,
                              backgroundColor: theme.palette.primary.main,
                              color: COLOR_GRAY_1,
                              cursor: "hand",
                              borderRadius: "50%",
                              padding: 1,
                            }}
                            onClick={() => {
                              setDeleteFilterNumber(filter.ruleFilterNumber);
                              return false;
                            }}
                          />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      )}
      {!props.viewMode && (
        <Grid container justifyContent="flex-end">
          <Button color="primary" variant="contained" onClick={addFilter}>
            <Add />
            {t("bonusRuleFilter.buttonNewFilter")}
          </Button>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  configGroups: state.configGroups.configGroups,
  originalBonusRules: state.bonusRules.bonusRules,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkDeleteBonusRuleFilter }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Filter);
