import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathBonusPremiumViewMode } from "../../api/url";
import {
  thunkActivateBonusPremium,
  thunkDeactivateBonusPremium,
  thunkDeleteBonusPremium,
} from "../../store/actions/BonusPremiumActions";
import { canMaintainBonusPremiums } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { BonusPremiumDto, BonusPremiumStatus } from "../../store/models/bonusPremium/BonusPremiumDto";
import { PageViewMode } from "../../store/models/PageViewMode";
import { useTranslation } from "react-i18next";
import KnistrConfirmDialog from "../atoms/KnistrConfirmDialog";

interface BonusPremiumTableMenuProps {
  bonusPremium: BonusPremiumDto;
}

function BonusPremiumTableMenu(props: BonusPremiumTableMenuProps & ThunkProps) {
  const { t } = useTranslation();
  const { bonusPremium } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  const open = Boolean(anchorEl);

  const canActivatePremium = (bonusPremium: BonusPremiumDto) =>
    canMaintainBonusPremiums() && bonusPremium.premiumStatus === BonusPremiumStatus.DISABLED;

  const canDeactivatePremium = (bonusPremium: BonusPremiumDto) =>
    canMaintainBonusPremiums() && bonusPremium.premiumStatus === BonusPremiumStatus.ENABLED;

  const canDeletePremium = () => canMaintainBonusPremiums();

  const canEditPremium = () => canMaintainBonusPremiums();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      {confirmVisible && (
        <KnistrConfirmDialog
          title={t("bonusPremium.deleteTitle")}
          description={t("bonusPremium.deleteDescription", {name: props.bonusPremium.premiumName})}
          cancelButton={t("common.cancel")}
          confirmButton={t("common.delete")}
          onConfirm={() => props.thunkDeleteBonusPremium(bonusPremium)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {canActivatePremium(bonusPremium) && (
          <MenuItem
            onClick={(e) => {
              props.thunkActivateBonusPremium(bonusPremium);
              handleClose(e);
            }}
          >
            {t('common.activate')}
          </MenuItem>
        )}
        {canDeactivatePremium(bonusPremium) && (
          <MenuItem
            onClick={(e) => {
              props.thunkDeactivateBonusPremium(bonusPremium);
              handleClose(e);
            }}
          >
            {t('common.deactivate')}
          </MenuItem>
        )}

        {canDeletePremium() && (
          <MenuItem
            onClick={(e) => {
              setConfirmVisible(true);
              handleClose(e);
            }}
          >
            {t('common.delete')}
          </MenuItem>
        )}

        {canEditPremium() && (
          <MenuItem onClick={handleClose}>
            <Link
              component={RouterLink}
              to={UrlPathBonusPremiumViewMode.replace(":id", bonusPremium.premiumNumber!).replace(
                ":viewMode",
                PageViewMode.EDIT
              )}
            >
              {t('common.edit')}
            </Link>
          </MenuItem>
        )}

        <MenuItem onClick={(e) => handleClose(e)}>
          <Link
            component={RouterLink}
            to={UrlPathBonusPremiumViewMode.replace(":id", bonusPremium.premiumNumber!).replace(
              ":viewMode",
              PageViewMode.VIEW
            )}
          >
             {t('common.view')}
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkActivateBonusPremium,
      thunkDeactivateBonusPremium,
      thunkDeleteBonusPremium,
    },
    dispatch
  );

const mapStateToProps = (_state: IStore) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusPremiumTableMenu);
