import { ConfigDto } from "./../../store/models/config/ConfigDto";
import { LocalDate } from "@js-joda/core";
import { ValidationErrors } from "final-form";
import { CustomerDto, CUSTOMER_FIELDS } from "../../store/models/customer/CustomerDto";
import {
  FieldConfigDto,
  getAllowUnchangedFields,
  getMandatoryFields,
  getOptionalFields,
} from "../../store/models/fieldConfig/FieldConfigDto";
import { RegistrationDto, REGISTRATION_FIELDS } from "../../store/models/registration/RegistrationDto";
import { isValidMailAddress, isValidZipCode } from "./Utils";
import { useTranslation } from "react-i18next";
import { UserDto } from "../../store/models/user/UserDto";

const MANDATORY_TEXT = "Pflichtfeld";

export function validateCustomerFields(
  errors: ValidationErrors,
  customer: CustomerDto,
  updatedCustomer: CustomerDto,
  customerFieldConfig: FieldConfigDto[]
) {
  if (!errors) return;
  if (!customerFieldConfig.length) return errors;

  const mandatoryMap = getMandatoryMap(CUSTOMER_FIELDS, customerFieldConfig);

  const allowUnchanged = getAllowUnchangedFields(customerFieldConfig);
  mandatoryMap.forEach((propertyName) => {
    const updatedPropertyValue = getValueByPropertyName(updatedCustomer, propertyName);
    if (!updatedPropertyValue) {
      const propertyValue = getValueByPropertyName(customer, propertyName);

      const propertyAllowUnchanged = allowUnchanged.includes(propertyName.toUpperCase());

      if ((propertyValue && propertyAllowUnchanged) || !propertyAllowUnchanged) {
        setErrors(errors, MANDATORY_TEXT, propertyName);
      }
    }
  });
  return errors;
}

export function validateRegistrationFields(
  errors: ValidationErrors,
  registration: RegistrationDto,
  registrationFieldConfig: FieldConfigDto[]
) {
  if (!registrationFieldConfig.length) return errors;

  const mandatoryMap = getMandatoryMap(REGISTRATION_FIELDS, registrationFieldConfig);

  mandatoryMap.forEach((propertyName) => {
    if (!getValueByPropertyName(registration, propertyName)) {
      if (propertyName !== REGISTRATION_FIELDS.EMAILCORRESPONDENCEALLOWED) {
        setErrors(errors, MANDATORY_TEXT, propertyName);
      }
    }
  });

  return errors;
}

function getValueByPropertyName(obj: any, propertyName: string) {
  return (
    obj && propertyName && propertyName.split(".").reduce((prev, cur) => (prev == null ? undefined : prev[cur]), obj)
  );
}

function setErrors(errors: ValidationErrors, errorMessage: string, propertyName: string) {
  if (!errors) return;
  if (!propertyName) return errors;
  const props = propertyName.split(".");

  if (props.length === 1) {
    errors[props[0]] = errorMessage;
  } else if (props.length === 2) {
    if (!errors[props[0]]) {
      errors[props[0]] = {};
    }
    errors[props[0]][props[1]] = errorMessage;
  }
}

export function isFieldMandatory(fieldName: string, fieldConfig: FieldConfigDto[]) {
  if (!fieldConfig.length) return undefined;
  const mandatoryFields = getMandatoryFields(fieldConfig);
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");
  return mandatoryFields.some((mandatoryField) => mandatoryField === fieldKeyFromName);
}

function isFieldOptional(fieldName: string, registrationFieldConfig: FieldConfigDto[]) {
  if (!registrationFieldConfig.length) return undefined;

  const optionalFields = getOptionalFields(registrationFieldConfig);
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return optionalFields.some((mandatoryField) => mandatoryField === fieldKeyFromName);
}

export function isFieldNeeded(fieldName: string, fieldConfig: FieldConfigDto[]) {
  if (!fieldConfig.length) return true;
  return isFieldMandatory(fieldName, fieldConfig) || isFieldOptional(fieldName, fieldConfig);
}

function getMandatoryMap(fieldNames: any, fieldConfig: FieldConfigDto[]): string[] {
  const mandatoryFields = getMandatoryFields(fieldConfig);
  if (!mandatoryFields) return [];
  return mandatoryFields.map((prop) => fieldNames[prop]).filter((propertyName) => propertyName);
}

export function formatName(name: string): string {
  return name
    .trim()
    .toLowerCase()
    .replace(/(^| |-)(?!de|der|van|von|zur|zum|zu)(\w)/g, (s) => s.toUpperCase());
}

export function validateNameLength(name: string): boolean {
  return name.split(/[ ,-]+/).every((a) => a.length > 1);
}

export function validateLatin(name: string): boolean {
  return /^[a-zA-ZÀ-ÿ\-'’` ]*$/.test(name);
}

export function useValidateDateOfBirth() {
  const { t } = useTranslation();

  const validateDateOfBirth = (errors: any, dateOfBirth: any) => {
    if (!errors) return;
    if (dateOfBirth) {
      const date = LocalDate.parse(dateOfBirth);
      if (date.isAfter(LocalDate.now().minusYears(18))) {
        errors.dateOfBirth = t("messages.validation.atLeast18YearsOld");
      }
      if (date.year() < 1900) {
        errors.dateOfBirth = t("messages.validation.invalidBirthDate");
      }
    }
    return errors;
  };

  return validateDateOfBirth;
}

export function useValidateName() {
  const { t } = useTranslation();

  function validateName(errors: ValidationErrors, firstName?: string, lastName?: string) {
    if (!errors) return;
    if (firstName) {
      firstName = formatName(firstName);
      if (!validateNameLength(firstName)) {
        errors.firstName = t("messages.validation.invalidFirstNameLength");
      }
      if (!validateLatin(firstName)) {
        errors.firstName = t("messages.validation.firstNameMustBeLatin");
      }
    }
    if (lastName) {
      lastName = formatName(lastName);
      if (!validateNameLength(lastName)) {
        errors.lastName = t("messages.validation.invalidLastNameLength");
      }
      if (!validateLatin(lastName)) {
        errors.lastName = t("messages.validation.lastNameMustBeLatin");
      }
    }
    return errors;
  }

  return validateName;
}

export function useValidateEmail() {
  const { t } = useTranslation();

  function validateEmail(email: string | undefined, errors: ValidationErrors, propertyName: string) {
    if (email && !isValidMailAddress(email)) {
      setErrors(errors, t("messages.validation.invalidEmail"), propertyName);
    }
  }
  return validateEmail;
}

export function useCheckDuplicateUsername() {
  const { t } = useTranslation();

  function checkDuplicateUsername(username: string | undefined, users: UserDto[], errors: ValidationErrors, propertyName: string) {
    if (username && users.find((user) => user.userName === username)) {
      setErrors(errors, t("messages.validation.duplicateUsername"), propertyName);
    }
  }
  return checkDuplicateUsername;
}

export function useCheckDuplicateEmail() {
  const { t } = useTranslation();

  function checkDuplicateEmail(email: string | undefined, users: UserDto[], errors: ValidationErrors, propertyName: string) {
    if (email && users.find((user) => user.mailAddress === email)) {
      setErrors(errors, t("messages.validation.duplicateEmail"), propertyName);
    }
  }
  return checkDuplicateEmail;
}

export function validatePassword(password: string | undefined, errors: ValidationErrors, propertyName: string) {
  if (password) {
    const minLength = /.{10,}/;
    const upperCase = /[A-Z]/;
    const lowerCase = /[a-z]/;
    const number = /\d/;
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/;

    let unmetCriteria = [];

    if (!minLength.test(password)) {
      unmetCriteria.push("mindestens 10 Zeichen");
    }
    if (!upperCase.test(password)) {
      unmetCriteria.push("1 Großbuchstaben");
    }
    if (!lowerCase.test(password)) {
      unmetCriteria.push("1 Kleinbuchstaben");
    }
    if (!number.test(password)) {
      unmetCriteria.push("1 Zahl");
    }
    if (!specialChar.test(password)) {
      unmetCriteria.push("1 Sonderzeichen");
    }

    if (unmetCriteria.length > 0) {
      let message: string;
      message = `Ihr Passwort muss ${unmetCriteria.join(", ").replace(/, ([^,]*)$/, " und $1")} enthalten.`;
      setErrors(errors, message, propertyName);
    }
  }
}

export function useValidateZipCode() {
  const { t } = useTranslation();
  function validateZipCode(zipCode: string | undefined, errors: ValidationErrors, propertyName: string) {
    if (zipCode && !isValidZipCode(zipCode)) {
      setErrors(errors, t("messages.validation.invalidZipCode"), propertyName);
    }
  }
  return validateZipCode;
}

export function useValidateRegistration() {
  const { t } = useTranslation();
  function validateRegistration(errors: ValidationErrors, password: string, passwordConfirm: string | undefined) {
    if (!errors) return;

    if (!errors.login) {
      errors.login = {};
    }
    if (!passwordConfirm) {
      errors.login.passwordConfirm = MANDATORY_TEXT;
    }
    if (password && passwordConfirm && password !== passwordConfirm) {
      errors.login.passwordConfirm = t("messages.validation.passwordConfirmationDoesNotMatch");
    }

    return errors;
  }
  return validateRegistration;
}

export function isVisibleField(fieldName: string, displayConfig: ConfigDto[]) {
  if (!displayConfig.length) return true;
  const fieldKeyFromName = fieldName.toLocaleUpperCase().replace(".", "_");

  return displayConfig.some((config) => config.key === fieldKeyFromName && config.value.toLocaleLowerCase() === "true");
}
