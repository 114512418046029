import { Button, Dialog, DialogContent, DialogTitle, Grid, useTheme } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";
import NotificationList from "../notification/NotificationList";

interface KnistrConfirmDialogProps {
  title: string;
  description: string;
  cancelButton: string;
  confirmButton: string;
  setConfirmVisible: (confirm: boolean) => void;
  onConfirm: () => void;
  hint?: ReactNode;
}

const KnistrConfirmDialog = (props: KnistrConfirmDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog onClose={() => props.setConfirmVisible(false)} open={true}>
      <NotificationList isDialog />
      <DialogTitle>{props.title}</DialogTitle>

      <DialogContent>
        <div style={{ marginBottom: theme.spacing(2) }}>
            {props.description}
        </div>
        {props.hint && <div style={{ marginBottom: theme.spacing(2) }}>{props.hint}</div>}
        <Grid container justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => props.setConfirmVisible(false)}
            style={{ margin: theme.spacing(0, 0, 2, 2) }}
          >
              {_.capitalize(props.cancelButton)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.onConfirm();
              props.setConfirmVisible(false);
            }}
            style={{ margin: theme.spacing(0, 0, 2, 2) }}
          >
            {_.capitalize(props.confirmButton)}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default KnistrConfirmDialog;
