import { Add, Close } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { Api } from "../../api/Api";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "../../store/actions/NotificationActions";
import { SegmentDto } from "../../store/models/segments/SegmentDto";
import { UpdateSegmentMembershipRequest } from "../../store/models/segments/UpdateSegmentMembershipRequest";

interface SegmentAssignedProps extends ThunkProps {
  assignedSegments: SegmentDto[];
  allSegments: SegmentDto[];
  externalCustomerId: string;
  onClose: () => void;
  onSave: (newAssignedSegments: SegmentDto[]) => void;
}

const SegmentAssigned = ({
  assignedSegments: initialAssignedSegments,
  allSegments,
  externalCustomerId,
  onClose,
  onSave,
  thunkCreateSuccessNotification,
}: SegmentAssignedProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [assignedSegments, setAssignedSegments] = useState<SegmentDto[]>(initialAssignedSegments);
  const [availableSegments, setAvailableSegments] = useState<SegmentDto[]>([]);

  useEffect(() => {
    setAssignedSegments(initialAssignedSegments);
    setAvailableSegments(
      allSegments.filter(
        (segment) =>
          !initialAssignedSegments.some((assigned) => assigned.externalSegmentId === segment.externalSegmentId)
      )
    );
  }, [initialAssignedSegments, allSegments]);

  const handleSegmentChange = (segment: SegmentDto, add: boolean) => {
    if (add) {
      setAssignedSegments([...assignedSegments, segment]);
      setAvailableSegments(availableSegments.filter((s) => s.externalSegmentId !== segment.externalSegmentId));
    } else {
      setAssignedSegments(assignedSegments.filter((s) => s.externalSegmentId !== segment.externalSegmentId));
      setAvailableSegments([...availableSegments, segment]);
    }
  };

  const handleSave = async () => {
    const newSegments = assignedSegments
      .filter(
        (segment) =>
          !initialAssignedSegments.some((assigned) => assigned.externalSegmentId === segment.externalSegmentId)
      )
      .map((segment) => ({ value: segment.externalSegmentId }));
    const removedSegments = initialAssignedSegments
      .filter(
        (segment) => !assignedSegments.some((assigned) => assigned.externalSegmentId === segment.externalSegmentId)
      )
      .map((segment) => ({ value: segment.externalSegmentId }));

    const request: UpdateSegmentMembershipRequest = { newSegments, removedSegments };

    try {
      await Api.updateSegmentMembershipsForCustomer(externalCustomerId, request);
      onSave(assignedSegments);
      thunkCreateSuccessNotification(t("segmentAssigned.successMessage"));
    } catch (error) {
      thunkCreateErrorNotification("Failed to fetch all segments", error);
    }
  };

  const renderSegments = (segments: SegmentDto[], add: boolean) => {
    return segments.map((segment) => (
      <Grid item key={segment.externalSegmentId} style={{ marginBottom: 8 }}>
        <Paper
          style={{
            padding: 8,
            backgroundColor: add ? "#f5f5f5" : "#e0f7fa",
            display: "flex",
            alignItems: "center",
            width: "fit-content",
          }}
        >
          <Typography style={{ marginRight: 4 }}>{segment.name}</Typography>
          {add ? (
            <Add
              data-testid="add-icon"
              onClick={() => handleSegmentChange(segment, true)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Close
              data-testid="close-icon"
              onClick={() => handleSegmentChange(segment, false)}
              style={{ cursor: "pointer" }}
            />
          )}
        </Paper>
      </Grid>
    ));
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" component="div" style={{ fontWeight: "bold" }}>
          {t("segmentAssigned.editDialogTitle")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              {t("segmentAssigned.assignedSegments")}
            </Typography>
            <Grid container spacing={2}>
              {renderSegments(assignedSegments, false)}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
            <Typography variant="subtitle1" color="textPrimary">
              {t("segmentAssigned.availableSegments")}
            </Typography>
            <Grid container spacing={2}>
              {renderSegments(availableSegments, true)}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={onClose} style={{ margin: theme.spacing(2, 0, 2, 2) }}>
          {t("common.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateSuccessNotification,
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;

export default connector(SegmentAssigned);
