import { Brightness1 } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Api } from "../../api/Api";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";
import { IStore } from "../../store/IStore";
import { CustomerIdentificationDto } from "../../store/models/identification/CustomerIdentificationDto";
import { IdentificationStatus } from "../../store/models/identification/IdentificationStatus";
import { IdentificationTypeDto } from "../../store/models/identification/IdentificationTypeDto";
import { PassRequestDto } from "../../store/models/pass/PassRequestDto";
import { DateTag } from "../atoms/DateTag";
import { FormSelect } from "../atoms/FormSelect";
import ImsEmbeddedTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import { COLOR_GRAY_4, KNISTR_GREEN, KNISTR_RED } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";

const Identifications = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { identificationTypes, customer } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedIdentificationType, setSelectedIdentificationType] = useState<string>("");
  const [passNumber, setPassNumber] = useState<string>("");
  const [identifications, setIdentifications] = useState<CustomerIdentificationDto[]>([]);

  useEffect(() => {
    const loadIdentifications = async () => {
      if (customer?.customerNumber) {
        try {
          const identifications = await Api.getCustomerIdentifications(customer.customerNumber);
          setIdentifications(identifications);
        } catch (error) {
          thunkCreateErrorNotification("Error", "Error loading identifications!");
        }
      }
    };
    loadIdentifications();
  }, [customer?.customerNumber]);

  const resetDialogState = () => {
    setSelectedIdentificationType("");
    setPassNumber("");
  };

  const handleCreatePass = async () => {
    if (!selectedIdentificationType) {
      thunkCreateErrorNotification("Error", t("identifications.error.selectPassType"));
      return;
    }

    if (isPassNumberRequired(selectedIdentificationType) && (!passNumber || passNumber.trim() === "")) {
      thunkCreateErrorNotification("Error", "Pass number cannot be empty or contain only spaces.");
      return;
    }

    try {
      const selectedType = getSelectedIdentificationType(selectedIdentificationType);
      const passRequest = createPassRequest(selectedType);
      await Api.createPass(passRequest);
      await reloadIdentifications();
      resetDialogState();
      setDialogOpen(false);
    } catch (error) {
      thunkCreateErrorNotification("Error", t("identifications.error.unexpected"));
    }
  };

  const getSelectedIdentificationType = (identificationTypeNumber: string) => {
    return identificationTypes.find((it) => it.identificationTypeNumber === identificationTypeNumber);
  };

  const createPassRequest = (selectedType: IdentificationTypeDto | undefined): PassRequestDto => {
    if (!selectedType || !selectedType.externalIdentificationTypeNumber) {
      thunkCreateErrorNotification("Error", t("identifications.error.invalidPassType"));
      throw new Error(t("identifications.error.invalidPassType"));
    }

    if (!customer || !customer.externalCustomerId) {
      thunkCreateErrorNotification("Error", t("identifications.error.invalidCustomer"));
      throw new Error(t("identifications.error.invalidCustomer"));
    }

    const baseRequest = {
      externalPassTypeNumber: selectedType.externalIdentificationTypeNumber,
      status: "ACTIVE",
      validFrom: new Date().toISOString().split("T")[0],
      connections: [
        {
          type: "CUSTOMER",
          externalConnectionId: customer.externalCustomerId,
        },
      ],
    };

    if (!selectedType.templateName) {
      // When no template exists, the pass number has to be set manually
      if (!passNumber) {
        throw new Error(t("identifications.error.passNumberRequired"));
      }
      return {
        passId: passNumber,
        ...baseRequest,
      };
    }

    return baseRequest;
  };

  const reloadIdentifications = async () => {
    if (customer?.customerNumber) {
      const identifications = await Api.getCustomerIdentifications(customer.customerNumber);
      setIdentifications(identifications);
    }
  };

  const isPassNumberRequired = (identificationTypeNumber: string): boolean => {
    const selectedType = identificationTypes.find((it) => it.identificationTypeNumber === identificationTypeNumber);
    return !selectedType?.templateName;
  };

  const handleOpenDialog = () => {
    resetDialogState();
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    resetDialogState();
    setDialogOpen(false);
  };

  const handleDropdownChange = (value: string) => {
    setSelectedIdentificationType(value);
    isPassNumberRequired(value);
  };

  const getIdentificationTypeName = (externalIdentificationTypeNumber: string): string | undefined =>
    identificationTypes?.find(
      (identificationType) => identificationType.externalIdentificationTypeNumber === externalIdentificationTypeNumber
    )?.identificationTypeName;

  const headCells: ImsHeadCell[] = [
    {
      title: t("customerCareDetailsPage.passHeadingStatus"),
      align: "center",
    },
    {
      title: t("customerCareDetailsPage.passHeadingPassId"),
      align: "center",
    },
    {
      title: t("customerCareDetailsPage.passHeadingType"),
      align: "center",
    },
    {
      title: t("customerCareDetailsPage.passHeadingValidFrom"),
      align: "center",
    },
  ];

  identifications.sort((a, b) => a.externalIdentificationTypeNumber.localeCompare(b.externalIdentificationTypeNumber));
  const rows: ImsTableRow[] = identifications.map((identification, index) => ({
    key: index,
    value: identification,
    nodes: [
      <Brightness1
        style={{
          fill: getIdentificationStatusColor(identification.status),
          marginRight: theme.spacing(1),
          paddingBottom: 2,
        }}
      />,
      identification.externalIdentificationNumber,
      getIdentificationTypeName(identification.externalIdentificationTypeNumber),
      <DateTag date={identification.validFrom} />,
    ],
  }));

  return (
    <Grid item md={12}>
      <Paper
        style={{
          padding: theme.spacing(3, 0, 3, 0),
          margin: theme.spacing(6, 0, 0, 0),
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center" style={{ padding: theme.spacing(0, 3) }}>
          <ImsPaperHead text={t("customerCareDetailsPage.passHeadline")} />
          <Button data-testid="create-pass-button" variant="contained" color="primary" onClick={handleOpenDialog}>
            {t("customerCareDetailsPage.createPassButton")}
          </Button>
        </Grid>
        <Grid
          item
          md={12}
          style={{
            padding: theme.spacing(0),
          }}
        >
          <ImsEmbeddedTable headCells={headCells} rows={rows} />
        </Grid>
      </Paper>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{t("customerCareDetailsPage.createPassDialogTitle")}</DialogTitle>
        <DialogContent style={{ minHeight: "200px" }}>
          <FormSelect
            testId="identificationTypeDropdown"
            name="identificationTypeNumber"
            label={t("setup.identificationType")}
            options={[{ label: t("setup.placeholder"), value: "" }].concat(
              identificationTypes.map((identificationType) => ({
                label: identificationType.identificationTypeName,
                value: identificationType.identificationTypeNumber as string,
              }))
            )}
            fullWidth={true}
            initialValues={[]}
            onChange={(value) => handleDropdownChange(value)}
          />
          {selectedIdentificationType && isPassNumberRequired(selectedIdentificationType) && (
            <TextField
              label={t("setup.passNumber")}
              value={passNumber}
              onChange={(e) => setPassNumber(e.target.value.trimStart())}
              fullWidth
              margin="normal"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="secondary"
            variant="contained"
            style={{ margin: theme.spacing(0, 0, 2, 2) }}
          >
            {t("setup.buttonCancel")}
          </Button>
          <Button
            onClick={handleCreatePass}
            color="primary"
            variant="contained"
            style={{ margin: theme.spacing(0, 0, 2, 2) }}
            disabled={!selectedIdentificationType || (isPassNumberRequired(selectedIdentificationType) && !passNumber)}
          >
            {t("setup.buttonSave")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export function getIdentificationStatusColor(status: IdentificationStatus): string {
  switch (status) {
    case IdentificationStatus.ACTIVE:
      return KNISTR_GREEN;
    case IdentificationStatus.INACTIVE:
      return KNISTR_RED;
    default:
      return COLOR_GRAY_4;
  }
}

const mapStateToProps = (state: IStore) => ({
  identifications: state.identifications?.identifications,
  identificationTypes: state.identifications?.identificationTypes,
  customer: state.customerCare.customer,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Identifications);
