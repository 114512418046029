import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationList from "../notification/NotificationList";
import HistoryFeedTable from "./HistoryFeedTable";
import { KNISTR_BLUE } from "../atoms/ImsMaterialTheme";
import SegmentFeedTable from "./SegmentFeedTable";

interface HistoryFeedDialogProps {
  setHistoryFeedDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HistoryFeedDialog = (props: HistoryFeedDialogProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog onClose={() => props.setHistoryFeedDialogVisible(false)} open={true} maxWidth="xl">
      <NotificationList isDialog />
      <DialogTitle>{t("customerCareDetailsPage.historyFeedHeadline")}</DialogTitle>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor={KNISTR_BLUE}
        textColor={KNISTR_BLUE}
        style={{ borderBottom: `1px solid ${theme.palette.divider}` }}
        sx={{
          "& .MuiTab-root": {
            color: "black",
          },
          "& .Mui-selected": {
            color: "black",
            fontWeight: "bold",
          },
        }}
      >
        <Tab label={t('customerCareDetailsPage.historyFeedAll')} />
        <Tab label={t('segmentHistory.headline')} />
      </Tabs>

      <DialogContent style={{ paddingBottom: theme.spacing(8) }}>
        {activeTab === 0 && <HistoryFeedTable />}
        {activeTab === 1 && <SegmentFeedTable />}
      </DialogContent>

      <DialogActions style={{ padding: theme.spacing(3) }}>
        <Button
          id="btndone"
          color="primary"
          type="button"
          variant="contained"
          onClick={() => {
            props.setHistoryFeedDialogVisible(false);
          }}
        >
          {t("customerCareDetailsPage.historyFeedButtonDone")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HistoryFeedDialog;
