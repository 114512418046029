import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathIdentificationTypeEdit } from "../../../api/url";
import { thunkDeleteIdentificationType } from "../../../store/actions/IdentificationActions";
import { canMaintainIdentificationTypes } from "../../../store/actions/LoginActions";
import { IStore } from "../../../store/IStore";
import { IdentificationTypeDto } from "../../../store/models/identification/IdentificationTypeDto";
import { useTranslation } from "react-i18next";
import KnistrConfirmDialog from "../../atoms/KnistrConfirmDialog";

interface IdentificationTypeTableMenuProps {
  identificationType: IdentificationTypeDto;
}

function IdentificationTypeTableMenu(props: IdentificationTypeTableMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
   const { t } = useTranslation();
  if (!canMaintainIdentificationTypes()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      {confirmVisible && (
        <KnistrConfirmDialog
          title={t("setup.deletePassTitle")}
          description={t("setup.deletePassDescription", {name: props.identificationType.identificationTypeName})}
          cancelButton={t("common.cancel")}
          confirmButton={t("common.delete")}
          onConfirm={() => props.thunkDeleteIdentificationType(props.identificationType)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link
            component={RouterLink}
            to={UrlPathIdentificationTypeEdit.replace(":id", props.identificationType.identificationTypeNumber!)}
          >
            {t("setup.kebabEdit")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setConfirmVisible(true);
            handleClose(e);
          }}
        >
          {t("setup.kebabDelete")}
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkDeleteIdentificationType }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(IdentificationTypeTableMenu);
