import { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import ImsTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import { useTranslation } from "react-i18next";
import { SegmentMembershipHistoryDto } from "../../store/models/segments/SegmentMembershipHistoryDto";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";
import { Api } from "../../api/Api";
import i18next from "i18next";
import { formatGermanDate } from "../atoms/Utils";

interface SegmentFeedTableProps {
  externalCustomerId: string;
}

const SegmentFeedTable: React.FC<ThunkProps & SegmentFeedTableProps> = (props) => {
  const { t } = useTranslation();
  const [segmentMembershipHistory, setSegmentMembershipHistory] = useState<SegmentMembershipHistoryDto[]>([]);
  const [, setLoading] = useState<boolean>(true);

  function getActionMessage(action: string, externalSegmentId: string): string {
    switch (action) {
      case "ASSIGNED":
        return i18next.t("segmentHistory.assigned") + externalSegmentId;
      case "UNASSIGNED":
        return i18next.t("segmentHistory.unassigned") + externalSegmentId;
      default:
        return "Unknown action";
    }
  }

  const loadSegments = useCallback(() => {
    setLoading(true);
    Api.getSegmentMembershipHistory(props.externalCustomerId)
      .then((data) => {
        setSegmentMembershipHistory(data);
      })
      .catch((e) => {
        setSegmentMembershipHistory([]);
        thunkCreateErrorNotification(t("segmentHistory.noData"), e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, props.externalCustomerId]);

  useEffect(() => {
    loadSegments();
  }, [loadSegments]);

  const sortedHistory = [...segmentMembershipHistory]
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  const headCells: ImsHeadCell[] = [
    { title: t("segmentHistory.date"), align: "left" },
    { title: t("segmentHistory.interaction"), align: "left", headAlign: "center" },
  ];

  const rows: ImsTableRow[] = sortedHistory.map((history) => ({
    key: history.timestamp,
    nodes: [
      <div>
        {formatGermanDate(history.timestamp)}
      </div>,
      <div>
        {getActionMessage(history.action, history.segmentName)}
      </div>,
    ],
  }));

  return <ImsTable headCells={headCells} rows={rows} verticalAlign="top" />;

};

const mapStateToProps = (state: IStore) => ({
  externalCustomerId: state.customerCare.customer?.externalCustomerId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      thunkCreateErrorNotification,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(SegmentFeedTable);
