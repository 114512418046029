import { Help } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkCreateFlowDetails, thunkUpdateFlowDetails } from "../../store/actions/FlowActions";
import { IStore } from "../../store/IStore";
import { FlowDetailsDto, FlowType, FlowTypeTrans } from "../../store/models/flow/FlowDto";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import { nameof, Options } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";

interface FlowDetailsDialogProps {
  flowDetail?: FlowDetailsDto;
  flowType: FlowType;
  flowGroupNumber: string;
  circleOptions: Options[];
  identificationTypeOptions: Options[];
  setFlowDetailsDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const FlowDetailsDialog = ({ setFlowDetailsDialogVisible, ...props }: FlowDetailsDialogProps & ThunkProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showThresholdHint, setShowThresholdHint] = useState(false);
  const [showMonthsHint, setShowMonthsHint] = useState(false);

  const handleMouseEnterThreshold = () => {
    if (!showThresholdHint) setShowThresholdHint(true);
  };

  const handleMouseLeaveThreshold = () => {
    if (showThresholdHint) setShowThresholdHint(false);
  };

  const handleMouseEnterMonths = () => {
    if (!showMonthsHint) setShowMonthsHint(true);
  };

  const handleMouseLeaveMonths = () => {
    if (showMonthsHint) setShowMonthsHint(false);
  };

  const submit = async (flow: FlowDetailsDto) => {
    let success;
    if (flow.flowDetailNumber) {
      success = await props.thunkUpdateFlowDetails({ ...flow }, props.flowGroupNumber);
    } else {
      success = await props.thunkCreateFlowDetails({ ...flow }, props.flowGroupNumber);
    }
    if (success) {
      setFlowDetailsDialogVisible(false);
    }
  };

  if (!props.flowDetail) {
    return null;
  }

  const boolOption: Options[] = [
    { value: "true", label: t("flow.yes") },
    { value: "false", label: t("flow.no") },
  ];

  const directionOption: Options[] = [
    { value: "UPGRADE", label: t("flow.upgrade") },
    { value: "DOWNGRADE", label: t("flow.downgrade") },
  ];

  const initialValues = { ...props.flowDetail };
  return (
    <Dialog onClose={() => setFlowDetailsDialogVisible(false)} open={true}>
      <NotificationList isDialog />
      <DialogTitle>
        <b>Detail Flow</b>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => {
            return (
              <form key="AdjustmentForm" onSubmit={handleSubmit}>
                <Grid container item spacing={2}>
                  <Grid container item spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <FormSelect
                        fullWidth={true}
                        name={nameof<FlowDetailsDto>("flowDirection")}
                        label={t("flow.labelDirection")}
                        options={directionOption}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <FormInput
                        type="number"
                        name={nameof<FlowDetailsDto>("threshold")}
                        label={t("flow.labelThreshold")}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <FormSelect
                        fullWidth={true}
                        name={nameof<FlowDetailsDto>("origin")}
                        label={"Start " + FlowTypeTrans[props.flowType]}
                        options={
                          props.flowType === FlowType.CIRCLE ? props.circleOptions : props.identificationTypeOptions
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <FormSelect
                        fullWidth={true}
                        name={nameof<FlowDetailsDto>("target")}
                        label={t("flow.labelTarget") + FlowTypeTrans[props.flowType]}
                        options={
                          props.flowType === FlowType.CIRCLE ? props.circleOptions : props.identificationTypeOptions
                        }
                      />
                    </Grid>
                  </Grid>
                  {props.flowType === FlowType.CIRCLE ? (
                    <Grid container item spacing={2}>
                      <Grid item xs={12}>
                        <FormSelect
                          fullWidth={true}
                          name={nameof<FlowDetailsDto>("immediate")}
                          label={t("flow.labelImmediately")}
                          options={boolOption}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <FormCheckbox
                        name={nameof<FlowDetailsDto>("informStatusChange")}
                        label={t("flow.labelInformStatusChange")}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <FormCheckbox
                        name={nameof<FlowDetailsDto>("informUpcomingStatusChange")}
                        label={t("flow.labelInformUpcomingStatusChange")}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <FormInput
                        type="number"
                        name={nameof<FlowDetailsDto>("thresholdReachedToInform")}
                        label={t("flow.labelThresholdReached")}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip
                        title={t("flow.titleTooltipThresholdReached")}
                        arrow
                        placement="top"
                        data-testid="helpIconThresholdReachedToInform"
                      >
                        <Help
                          color="primary"
                          onMouseEnter={handleMouseEnterThreshold}
                          onMouseLeave={handleMouseLeaveThreshold}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <FormInput
                        type="number"
                        name={nameof<FlowDetailsDto>("monthsAdvanceToInform")}
                        label={t("flow.labelMonthsInAdvance")}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip
                        title={t("flow.titleTooltipMonthsInAdvance")}
                        arrow
                        placement="top"
                        data-testid="helpIconMonthsAdvanceToInform"
                      >
                        <Help
                          color="primary"
                          onMouseEnter={handleMouseEnterMonths}
                          onMouseLeave={handleMouseLeaveMonths}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{
                    paddingTop: theme.spacing(4),
                  }}
                >
                  <Grid item>
                    <Button
                      color="secondary"
                      type="button"
                      variant="contained"
                      onClick={() => setFlowDetailsDialogVisible(false)}
                    >
                      {t("flow.buttonBack")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" disabled={submitting}>
                      {t("flow.buttonSaveFlow")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkUpdateFlowDetails, thunkCreateFlowDetails }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FlowDetailsDialog);
