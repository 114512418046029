import { Help } from "@mui/icons-material";
import { Button, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { Api } from "../../../../api/Api";
import { UrlPathSegment } from "../../../../api/url";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "../../../../store/actions/NotificationActions";
import { SegmentDto } from "../../../../store/models/segments/SegmentDto";
import { FormCheckbox } from "../../../atoms/FormCheckbox";
import { FormInput } from "../../../atoms/FormInput";
import ImsPaperHead from "../../../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../../../atoms/Utils";

interface Step1Props {
  onNext: (values: SegmentDto) => void;
  onValidationChange: (isValid: boolean) => void;
  initialValues?: SegmentDto;
  onBack: () => void;
  backButtonText?: string;
}

const Step1 = ({ onNext, onValidationChange, initialValues, onBack, backButtonText, thunkCreateSuccessNotification, thunkCreateErrorNotification }: Step1Props & ConnectedProps<typeof connector>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validateForm = (values: SegmentDto) => {
    const errors: ValidationErrors<SegmentDto> = {};
    if (isBlank(values.name)) {
      errors.name = t("messages.validation.segmentNameMandatory");
    }
    if (isBlank(values.externalSegmentId)) {
      errors.externalSegmentId = t("messages.validation.segmentExternalNumberMandatory");
    }
    return errors;
  };

  const handleSave = async (values: SegmentDto) => {
    await Api.createSegment(values)
    .then(() => {
      thunkCreateSuccessNotification(t("segmentBuilder.csv.step1.segmentCreated"));
      navigate(UrlPathSegment);
    })
    .catch((e) => {
      thunkCreateErrorNotification(e.message, e);
    });
  };

  return (
    <Form
      onSubmit={onNext}
      validate={validateForm}
      initialValues={initialValues}
      render={({ handleSubmit, valid, values }) => {
        // Update parent's validation state
        onValidationChange(valid);

        return (
          <form onSubmit={handleSubmit}>
            <Paper style={{ padding: theme.spacing(3), marginTop: theme.spacing(6) }}>
              <ImsPaperHead text={t("segmentBuilder.csv.step1.title")} />
              <Grid container spacing={2}>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="name"
                    label={t("segmentBuilder.csv.step1.segmentName")}
                  />
                </Grid>
                <Grid item sm={8}></Grid>

                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth
                    type="text"
                    name={nameof<SegmentDto>("externalSegmentId")}
                    label={t("segmentBuilder.csv.step1.segmentId")}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Tooltip title={t("segmentBuilder.csv.step1.segmentIdInfo")} placement="right-start" style={{ marginTop: 16 }}>
                    <Help color="primary" />
                  </Tooltip>
                </Grid>

                <Grid item container spacing={2} alignItems="center">
                  <Grid item>
                    <FormCheckbox name="defaultSegment" label={t("segmentBuilder.csv.step1.checkBoxAssignOnRegistration")} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                color="secondary"
                variant="contained"
                style={{ margin: theme.spacing(2, 0, 2, 0), minWidth: 150 }}
                onClick={onBack}
              >
                {backButtonText || t("common.back")}
              </Button>

              <div style={{ display: "flex", gap: theme.spacing(2) }}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 0), minWidth: 150 }}
                  onClick={() => handleSave(values)}
                  disabled={!valid}
                >
                  {t("segmentBuilder.csv.step1.buttonSave")}
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ margin: theme.spacing(2, 0, 2, 0), minWidth: 150 }}
                  disabled={!valid}
                >
                  {t("common.next")}
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateSuccessNotification,
      thunkCreateErrorNotification,
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);

export default connector(Step1);
