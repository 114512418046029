import { Help } from "@mui/icons-material";
import { FormControl, Grid, TextField, Tooltip, Typography } from "@mui/material";
import { Field } from "react-final-form";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded } from "./FieldValidation";

interface FormInputProps {
  type: string;
  name: string;
  label: string;
  readOnly?: boolean;
  variant?: string;
  fullWidth?: boolean;
  prefix?: string;
  helperText?: string;
  tooltip?: string;
  testId?: string;
  multiline?: boolean;
  autoComplete?: string;
  fieldConfig?: FieldConfigDto[];
  rows?: number;
}

export const FormInput = (props: FormInputProps) => {
  if (props.fieldConfig && !isFieldNeeded(props.name, props.fieldConfig)) return null;
  return (
    <Field name={props.name} type={props.type}>
      {({ input, meta }) => {
        const error = meta.touched && meta.error;
        const helperText = props.helperText ?? error;
        const containerSpacing = props.prefix ?? props.tooltip ? 2 : 0;
        const itemSpacing = props.prefix ?? props.tooltip ? 9 : 12;
        const id = "textField-" + props.name;
        const dataTestId = props.testId ?? id;
        return (
          <FormControl fullWidth={props.fullWidth} style={{ minHeight: '85px' }}>
            {props.variant === "outlined" ? (
              <Grid container spacing={containerSpacing}>
                {props.prefix && (
                  <Grid item style={{ margin: "auto" }}>
                    <Typography>{props.prefix}</Typography>
                  </Grid>
                )}
                <Grid item xs={itemSpacing}>
                  <TextField
                    {...input}
                    disabled={props.readOnly}
                    data-testid={dataTestId}
                    id={id}
                    type={props.type}
                    label={props.label}
                    variant="outlined"
                    multiline={props.multiline}
                    fullWidth={props.fullWidth}
                    helperText={helperText}
                    error={Boolean(error)}
                    autoComplete={props.autoComplete}
                    rows={props.rows}
                  />
                </Grid>
                {props.tooltip && (
                  <Grid item>
                    <Tooltip title={props.tooltip} placement="right-start" style={{ marginTop: 16 }}>
                      <Help color="primary" />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ) : (
              <TextField
                {...input}
                disabled={props.readOnly}
                data-testid={dataTestId}
                id={id}
                type={props.type}
                label={props.label}
                variant="standard"
                multiline={props.type === "textarea"}
                helperText={helperText}
                error={Boolean(error)}
                autoComplete={props.autoComplete}
                rows={props.rows}
              />
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};
