import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Navigate, Routes } from "react-router";
import { Route } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import {
  isPublicPath,
  UrlPathAppContent,
  UrlPathAppContentCarousel,
  UrlPathAppContentCreate,
  UrlPathAppContentEdit,
  UrlPathAppContentLinks,
  UrlPathAppContentSplash,
  UrlPathBonus,
  UrlPathBonusCreate,
  UrlPathBonusPremium,
  UrlPathBonusPremiumCreate,
  UrlPathBonusPremiumViewMode,
  UrlPathBonusViewMode,
  UrlPathBonusXpress,
  UrlPathCampaignCreate,
  UrlPathCampaignEdit,
  UrlPathCampaigns,
  UrlPathCarouselCreate,
  UrlPathCarouselEdit,
  UrlPathCashback,
  UrlPathCircle,
  UrlPathCircleCreate,
  UrlPathCircleEdit,
  UrlPathConfigValueCreate,
  UrlPathConfigValueEdit,
  UrlPathCustomerDetails,
  UrlPathCustomerEdit,
  UrlPathCustomerPreferences,
  UrlPathCustomerSearch,
  UrlPathCustomField,
  URlPathDownloadFile,
  UrlPathFamily,
  UrlPathFlow,
  UrlPathFlowCreate,
  UrlPathFlowEdit,
  UrlPathGdpr,
  UrlPathIdentificationType,
  UrlPathIdentificationTypeCreate,
  UrlPathIdentificationTypeEdit,
  UrlPathInteractionSearch,
  URlPathLanding,
  UrlPathMain,
  UrlPathOperationalUnit,
  UrlPathOperationalUnitCreate,
  UrlPathOperationalUnitEdit,
  UrlPathPassMedium,
  UrlPathPassMediumCreate,
  UrlPathPassMediumEdit,
  URlPathPkPassDownload,
  UrlPathPreferences,
  UrlPathPreferencesCreate,
  UrlPathPreferencesEdit,
  UrlPathReceiptDetail,
  UrlPathRegistration,
  UrlPathReports,
  UrlPathSegment,
  UrlPathSegmentBuilder,
  UrlPathSegmentBuilderCsv,
  UrlPathSegmentCreate,
  UrlPathSegmentDetail,
  UrlPathUser,
  UrlPathUserCreate,
  UrlPathUserEdit
} from "../../api/url";
import keycloak from "../../keycloak";
import { thunkGetAllAppLinks } from "../../store/actions/AppLinkActions";
import { thunkGetBonusPremiums } from "../../store/actions/BonusPremiumActions";
import { thunkGetBonusRules } from "../../store/actions/BonusRuleActions";
import { thunkGetBonusXpress } from "../../store/actions/BonusXpressActions";
import { thunkFindAllCampaigns } from "../../store/actions/CampaignActions";
import { thunkGetAllCircles } from "../../store/actions/CircleActions";
import { thunkGetAllConfigGroups } from "../../store/actions/ConfigGroupActions";
import { thunkGetAllEinGeneratorTemplates } from "../../store/actions/EinGeneratorTemplateAction";
import { thunkGetAllFlows } from "../../store/actions/FlowActions";
import { thunkGetAllIdentificationTypes } from "../../store/actions/IdentificationActions";
import {
  canMaintainAppContent,
  canMaintainBonus,
  canMaintainBonusPremiums,
  canMaintainCampaigns,
  canMaintainCircles,
  canMaintainConfig,
  canMaintainCustomers,
  canMaintainFamilies,
  canMaintainFlows,
  canMaintainIdentifications,
  canMaintainIdentificationTypes,
  canMaintainOperationalUnits,
  canMaintainPreferences,
  canMaintainSegments,
  canMaintainUsers,
  canReadAppContent,
  canReadBonus,
  canReadBonusPremiums,
  canReadCampaigns,
  canReadCashBack,
  canReadCircles,
  canReadConfig,
  canReadCustomerInteractions,
  canReadCustomerPreferences,
  canReadCustomers,
  canReadDashboard,
  canReadFlows,
  canReadGdpr,
  canReadIdentifications,
  canReadIdentificationTypes,
  canReadOperationalUnits,
  canReadPreferences,
  canReadReports,
  canReadUsers,
} from "../../store/actions/LoginActions";
import {
  thunkGetDefaultOperationalUnitTypes,
  thunkGetOperationalUnits,
} from "../../store/actions/OperationalUnitActions";
import { thunkGetAllPassMediums } from "../../store/actions/PassMediumAction";
import { thunkGetQuestions } from "../../store/actions/PreferenceActions";
import { thunkFindAllUsers } from "../../store/actions/UserActions";
import { IStore } from "../../store/IStore";
import AppContentPage from "../appContent/AppContentPage";
import CarouselFormPage from "../appContent/carousel/AppContentCarouselFormPage";
import AppLinkFormPage from "../appContent/links/AppLinkFormPage";
import BonusPremiumFormPage from "../bonusPremium/BonusPremiumFormPage";
import { BonusPremiumPage } from "../bonusPremium/BonusPremiumPage";
import BonusRuleFormPage from "../bonusRule/BonusRuleFormPage";
import { BonusRulePage } from "../bonusRule/BonusRulePage";
import BonusXpressPage from "../bonusXpress/BonusXpressPage";
import CampaignFormPage from "../campaign/CampaignFormPage";
import { CampaignPage } from "../campaign/CampaignPage";
import CashbackPage from "../cashback/CashbackPage";
import ErrorPage from "../common/ErrorPage";
import CustomerCareDetailsPage from "../customerCare/CustomerCareDetailsPage";
import CustomerCareEditPage from "../customerCare/CustomerCareEditPage";
import CustomerCarePreferencesPage from "../customerCare/CustomerCarePreferencesPage";
import CustomerCareSearchPage from "../customerCare/CustomerCareSearchPage";
import FamilyPage from "../customerCare/FamilyPage";
import { MainDashboardPage } from "../dashboard/MainDashboardPage";
import Flows from "../flow/Flows";
import InteractionSearchPage from "../interactionSearch/InteractionSearchPage";
import OperationalUnitFormPage from "../operationalUnit/OperationalUnitFormPage";
import OperationalUnitOverview from "../operationalUnit/OperationalUnitOverview";
import PreferencesFormPage from "../preferences/PreferencesFormPage";
import { PreferencesPage } from "../preferences/PreferencesPage";
import { RegistrationPage } from "../registration/RegistrationPage";
import ReportPage from "../report/ReportPage";
import CreateCustomSegments from "../segments/builder/csv/CreateCustomSegments";
import SegmentsBuilder from "../segments/builder/SegmentsBuilder";
import SegmentFormPage from "../segments/SegmentFormPage";
import SegmentsPage from "../segments/SegmentsPage";
import CircleFormPage from "../setup/circle/CircleFormPage";
import ConfigValueFormPage, { customFieldProps, receiptDetailProps } from "../setup/configValue/ConfigValueFormPage";
import IdentificationTypeFormPage from "../setup/identificationType/IdentificationTypeFormPage";
import PassMediumFormPage from "../setup/passmedium/PassMediumFormPage";
import Setup from "../setup/Setup";
import UserFormPage from "../user/UserFormPage";
import { UserTablePage } from "../user/UserTablePage";
import LandingPage from "./LandingPage";
import PkPassDownloadPage from "./PkPassDownloadPage";
import DownloadFilePage from "./DownloadFilePage";

const App = (props: ThunkProps) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    keycloak.onAuthSuccess = () => {
      canReadPreferences() && props.thunkGetQuestions();
      canReadBonus() && props.thunkGetBonusRules();
      canReadBonus() && props.thunkGetBonusXpress();
      canReadBonusPremiums() && props.thunkGetBonusPremiums();
      canReadCampaigns() && props.thunkFindAllCampaigns();
      canReadOperationalUnits() && props.thunkGetOperationalUnits();
      canReadOperationalUnits() && props.thunkGetDefaultOperationalUnitTypes();
      canReadUsers() && props.thunkFindAllUsers();
      canReadCircles() && props.thunkGetAllCircles();
      canMaintainIdentifications() && props.thunkGetAllPassMediums();
      canReadIdentificationTypes() && props.thunkGetAllIdentificationTypes();
      canReadFlows() && props.thunkGetAllFlows();
      canReadConfig() && props.thunkGetAllConfigGroups();
      canReadConfig() && props.thunkGetAllEinGeneratorTemplates();
    };

    keycloak.onAuthLogout = () => {
      keycloak.login();
    };
  }, [keycloak, props]);

  if (!initialized) {
    return <></>;
  }

  if (!keycloak.authenticated && !isPublicPath()) {
    keycloak.login();
    return <></>;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/loyalty" replace />} />
      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/loyalty/login" element={<Navigate to="/" replace />} />
      <Route path={URlPathLanding} element={<LandingPage />} />
      <Route path={URlPathPkPassDownload} element={<PkPassDownloadPage />} />
      <Route path={URlPathDownloadFile} element={<DownloadFilePage />} />
      <Route
        path={UrlPathMain}
        element={
          <AuthOrElse check={canReadDashboard} element={<MainDashboardPage />} pathToNavigate={UrlPathCustomerSearch} />
        }
      />
      <Route path={UrlPathReports} element={<Auth check={canReadReports} element={<ReportPage />} />} />
      <Route
        path={UrlPathInteractionSearch}
        element={<Auth check={canReadCustomerInteractions} element={<InteractionSearchPage />} />}
      />
      <Route
        path={UrlPathCustomerSearch}
        element={<Auth check={canReadCustomers} element={<CustomerCareSearchPage />} />}
      />
      <Route
        path={UrlPathCustomerEdit}
        element={<Auth check={canMaintainCustomers} element={<CustomerCareEditPage />} />}
      />
      <Route
        path={UrlPathCustomerDetails}
        element={<Auth check={canReadCustomers} element={<CustomerCareDetailsPage />} />}
      />

      <Route path={UrlPathPreferences} element={<Auth check={canReadPreferences} element={<PreferencesPage />} />} />
      <Route
        path={UrlPathPreferencesCreate}
        element={<Auth check={canMaintainPreferences} element={<PreferencesFormPage />} />}
      />
      <Route
        path={UrlPathPreferencesEdit}
        element={<Auth check={canMaintainPreferences} element={<PreferencesFormPage />} />}
      />
      <Route
        path={UrlPathCustomerPreferences}
        element={<Auth check={canReadCustomerPreferences} element={<CustomerCarePreferencesPage />} />}
      />
      <Route path={UrlPathBonusXpress} element={<Auth check={canReadBonus} element={<BonusXpressPage />} />} />
      <Route path={UrlPathAppContent} element={<Auth check={canReadAppContent} element={<AppContentPage />} />} />
      <Route path={UrlPathAppContentLinks} element={<Auth check={canReadAppContent} element={<AppContentPage />} />} />
      <Route
        path={UrlPathAppContentCarousel}
        element={<Auth check={canReadAppContent} element={<AppContentPage />} />}
      />
      <Route path={UrlPathAppContentSplash} element={<Auth check={canReadAppContent} element={<AppContentPage />} />} />
      <Route
        path={UrlPathAppContentCreate}
        element={<Auth check={canMaintainAppContent} element={<AppLinkFormPage />} />}
      />
      <Route
        path={UrlPathAppContentEdit}
        element={<Auth check={canMaintainAppContent} element={<AppLinkFormPage />} />}
      />
      <Route
        path={UrlPathCarouselCreate}
        element={<Auth check={canMaintainAppContent} element={<CarouselFormPage />} />}
      />
      <Route
        path={UrlPathCarouselEdit}
        element={<Auth check={canMaintainAppContent} element={<CarouselFormPage />} />}
      />
      <Route path={UrlPathBonus} element={<Auth check={canReadBonus} element={<BonusRulePage />} />} />
      <Route path={UrlPathBonusCreate} element={<Auth check={canMaintainBonus} element={<BonusRuleFormPage />} />} />
      <Route path={UrlPathBonusViewMode} element={<Auth check={canReadBonus} element={<BonusRuleFormPage />} />} />
      <Route
        path={UrlPathBonusPremium}
        element={<Auth check={canReadBonusPremiums} element={<BonusPremiumPage />} />}
      />
      <Route
        path={UrlPathBonusPremiumCreate}
        element={<Auth check={canMaintainBonusPremiums} element={<BonusPremiumFormPage />} />}
      />
      <Route
        path={UrlPathBonusPremiumViewMode}
        element={<Auth check={canReadBonusPremiums} element={<BonusPremiumFormPage />} />}
      />
      <Route path={UrlPathCampaigns} element={<Auth check={canReadCampaigns} element={<CampaignPage />} />} />
      <Route path={UrlPathCashback} element={<Auth check={canReadCashBack} element={<CashbackPage />} />} />
      <Route
        path={UrlPathCampaignCreate}
        element={<Auth check={canMaintainCampaigns} element={<CampaignFormPage />} />}
      />
      <Route
        path={UrlPathCampaignEdit}
        element={<Auth check={canMaintainCampaigns} element={<CampaignFormPage />} />}
      />
      <Route
        path={UrlPathOperationalUnit}
        element={<Auth check={canReadOperationalUnits} element={<OperationalUnitOverview />} />}
      />
      <Route
        path={UrlPathOperationalUnitCreate}
        element={<Auth check={canMaintainOperationalUnits} element={<OperationalUnitFormPage />} />}
      />
      <Route
        path={UrlPathOperationalUnitEdit}
        element={<Auth check={canMaintainOperationalUnits} element={<OperationalUnitFormPage />} />}
      />
      <Route path={UrlPathSegment} element={<Auth check={canMaintainSegments} element={<SegmentsPage />} />} />
      <Route path={UrlPathSegmentBuilder} element={<Auth check={canMaintainSegments} element={<SegmentsBuilder />} />} />
      <Route path={UrlPathSegmentBuilderCsv} element={<Auth check={canMaintainSegments} element={<CreateCustomSegments />} />} />
      <Route path={UrlPathSegmentCreate} element={<Auth check={canMaintainSegments} element={<SegmentFormPage />} />} />
      <Route path={UrlPathSegmentDetail} element={<Auth check={canMaintainSegments} element={<SegmentFormPage />} />} />
      <Route path={UrlPathCircle} element={<Auth check={canReadCircles} element={<Setup />} />} />
      <Route path={UrlPathCircleCreate} element={<Auth check={canMaintainCircles} element={<CircleFormPage />} />} />
      <Route path={UrlPathCircleEdit} element={<Auth check={canMaintainCircles} element={<CircleFormPage />} />} />
      <Route
        path={UrlPathIdentificationType}
        element={<Auth check={canReadIdentificationTypes} element={<Setup />} />}
      />
      <Route
        path={UrlPathIdentificationTypeCreate}
        element={<Auth check={canMaintainIdentificationTypes} element={<IdentificationTypeFormPage />} />}
      />
      <Route
        path={UrlPathIdentificationTypeEdit}
        element={<Auth check={canMaintainIdentificationTypes} element={<IdentificationTypeFormPage />} />}
      />
      <Route path={UrlPathPassMedium} element={<Auth check={canReadIdentifications} element={<Setup />} />} />
      <Route
        path={UrlPathPassMediumCreate}
        element={<Auth check={canMaintainIdentifications} element={<PassMediumFormPage />} />}
      />
      <Route
        path={UrlPathPassMediumEdit}
        element={<Auth check={canMaintainIdentifications} element={<PassMediumFormPage />} />}
      />
      <Route path={UrlPathFlow} element={<Auth check={canReadFlows} element={<Flows />} />} />
      <Route path={UrlPathFlowCreate} element={<Auth check={canMaintainFlows} element={<Flows />} />} />
      <Route path={UrlPathFlowEdit} element={<Auth check={canMaintainFlows} element={<Flows />} />} />
      <Route path={UrlPathReceiptDetail} element={<Auth check={canReadConfig} element={<Setup />} />} />
      <Route
        path={UrlPathConfigValueCreate(UrlPathReceiptDetail)}
        element={<Auth check={canMaintainConfig} element={<ConfigValueFormPage {...receiptDetailProps} />} />}
      />
      <Route
        path={UrlPathConfigValueEdit(UrlPathReceiptDetail)}
        element={<Auth check={canMaintainConfig} element={<ConfigValueFormPage {...receiptDetailProps} />} />}
      />
      <Route path={UrlPathCustomField} element={<Auth check={canReadConfig} element={<Setup />} />} />
      <Route
        path={UrlPathConfigValueCreate(UrlPathCustomField)}
        element={<Auth check={canMaintainConfig} element={<ConfigValueFormPage {...customFieldProps} />} />}
      />
      <Route
        path={UrlPathConfigValueEdit(UrlPathCustomField)}
        element={<Auth check={canMaintainConfig} element={<ConfigValueFormPage {...customFieldProps} />} />}
      />
      <Route path={UrlPathGdpr} element={<Auth check={canReadGdpr} element={<Setup />} />} />
      <Route path={UrlPathUser} element={<Auth check={canReadUsers} element={<UserTablePage />} />} />
      <Route path={UrlPathUserCreate} element={<Auth check={canMaintainUsers} element={<UserFormPage />} />} />
      <Route path={UrlPathUserEdit} element={<Auth check={canMaintainUsers} element={<UserFormPage />} />} />
      <Route path={UrlPathRegistration} element={<RegistrationPage />} />
      <Route path={UrlPathFamily} element={<Auth check={canMaintainFamilies} element={<FamilyPage />} />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

const Auth = ({ element, check }: { element: JSX.Element; check: () => boolean }) => (check() ? element : null);

const AuthOrElse = ({
  element,
  pathToNavigate,
  check,
}: {
  element: JSX.Element;
  pathToNavigate: string;
  check: () => boolean;
}) => {
  if (!keycloak.authenticated) {
    return null;
  }

  return check() ? element : <Navigate to={pathToNavigate} />;
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetQuestions,
      thunkGetBonusRules,
      thunkGetBonusPremiums,
      thunkFindAllCampaigns,
      thunkGetOperationalUnits,
      thunkGetDefaultOperationalUnitTypes,
      thunkFindAllUsers,
      thunkGetAllAppLinks,
      thunkGetAllCircles,
      thunkGetAllPassMediums,
      thunkGetAllIdentificationTypes,
      thunkGetAllFlows,
      thunkGetBonusXpress,
      thunkGetAllConfigGroups,
      thunkGetAllEinGeneratorTemplates,
    },
    dispatch
  );

type ThunkProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IStore) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
