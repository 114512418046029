export enum AccessRole {
  ADMIN_PASSWORD_MAINTAIN = "ADMIN_PASSWORD_MAINTAIN",
  ADMIN_USER_MAINTAIN = "ADMIN_USER_MAINTAIN",
  ADMIN_USER_READONLY = "ADMIN_USER_READONLY",
  APP_CONTENT_MAINTAIN = "APP_CONTENT_MAINTAIN",
  APP_CONTENT_READONLY = "APP_CONTENT_READONLY",
  BONUS_MAINTAIN = "BONUS_MAINTAIN",
  BONUS_READONLY = "BONUS_READONLY",
  CAMPAIGN_MAINTAIN = "CAMPAIGN_MAINTAIN",
  CAMPAIGN_READONLY = "CAMPAIGN_READONLY",
  CASHBACK_CONFIG_MAINTAIN = "CASHBACK_CONFIG_MAINTAIN",
  CASHBACK_CONFIG_READONLY = "CASHBACK_CONFIG_READONLY",
  CIRCLE_MAINTAIN = "CIRCLE_MAINTAIN",
  CIRCLE_READONLY = "CIRCLE_READONLY",
  CONFIG_MAINTAIN = "CONFIG_MAINTAIN",
  CONFIG_READONLY = "CONFIG_READONLY",
  CUSTOMER_INTERACTION_MAINTAIN = "CUSTOMER_INTERACTION_MAINTAIN",
  CUSTOMER_INTERACTION_READONLY = "CUSTOMER_INTERACTION_READONLY",
  CUSTOMER_MAINTAIN = "CUSTOMER_MAINTAIN",
  CUSTOMER_PREFERENCE_MAINTAIN = "CUSTOMER_PREFERENCE_MAINTAIN",
  CUSTOMER_PREFERENCE_READONLY = "CUSTOMER_PREFERENCE_READONLY",
  CUSTOMER_READONLY = "CUSTOMER_READONLY",
  DASHBOARD_READONLY = "DASHBOARD_READONLY",
  FAMILY_MAINTAIN = "FAMILY_MAINTAIN",
  FAMILY_READONLY = "FAMILY_READONLY",
  FLOW_MAINTAIN = "FLOW_MAINTAIN",
  FLOW_READONLY = "FLOW_READONLY",
  GDPR_MAINTAIN = "GDPR_MAINTAIN",
  GDPR_READONLY = "GDPR_READONLY",
  IDENTITY_MAINTAIN = "IDENTITY_MAINTAIN",
  IDENTITY_READONLY = "IDENTITY_READONLY",
  IDENTITY_TYPE_MAINTAIN = "IDENTITY_TYPE_MAINTAIN",
  IDENTITY_TYPE_READONLY = "IDENTITY_TYPE_READONLY",
  PREFERENCE_MAINTAIN = "PREFERENCE_MAINTAIN",
  PREFERENCE_READONLY = "PREFERENCE_READONLY",
  PREMIUM_MAINTAIN = "PREMIUM_MAINTAIN",
  PREMIUM_READONLY = "PREMIUM_READONLY",
  PROPERTYGROUP_MAINTAIN = "PROPERTYGROUP_MAINTAIN",
  PROPERTYGROUP_READONLY = "PROPERTYGROUP_READONLY",
  REPORT_READONLY = "REPORT_READONLY",
  SEGMENT_MAINTAIN = "SEGMENT_MAINTAIN",
  SEGMENT_READONLY = "SEGMENT_READONLY",
  SUPERUSER_MAINTAIN = "SUPERUSER_MAINTAIN",
  SUPERUSER_READONLY = "SUPERUSER_READONLY",
  UNIT_MAINTAIN = "UNIT_MAINTAIN",
  UNIT_READONLY = "UNIT_READONLY",
}
