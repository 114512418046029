import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { canMaintainAppContent } from "../../../store/actions/LoginActions";
import { CarouselDto } from "../../../store/models/appcontent/CarouselDto";
import { thunkDeleteCarousel } from "../../../store/actions/CarouselActions";
import { UrlPathCarouselEdit } from "../../../api/url";
import { useTranslation } from "react-i18next";
import KnistrConfirmDialog from "../../atoms/KnistrConfirmDialog";

interface CarouselMenuProps {
  carousel: CarouselDto;
}

function AppContentCarouselMenu(props: CarouselMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      {confirmVisible && (
        <KnistrConfirmDialog
          title={t("appContentCarouselDelete.title")}
          description={t("appContentCarouselDelete.description", {title: props.carousel.title})}
          cancelButton={t("common.cancel")}
          confirmButton={t("common.delete")}
          onConfirm={() => props.thunkDeleteCarousel(props.carousel)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {canMaintainAppContent() && (
          <MenuItem onClick={handleClose}>
            <Link component={RouterLink} to={UrlPathCarouselEdit.replace(":id", props.carousel.carouselNumber!)}>
              {t("appContentCarousel.kebabMenuEdit")}
            </Link>
          </MenuItem>
        )}
        {canMaintainAppContent() && (
          <MenuItem
            onClick={(e) => {
              setConfirmVisible(true);
              handleClose(e);
            }}
          >
            {t("appContentCarousel.kebabMenuDelete")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkDeleteCarousel }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AppContentCarouselMenu);
