import { KNISTR_YELLOW } from "./ImsMaterialTheme";

interface YellowDotProps extends React.HTMLAttributes<HTMLDivElement> {}

export const YellowDot: React.FC<YellowDotProps> = ({ style, ...rest }) => {
  return (
    <div
      {...rest} // Spread other props like className, onClick, etc.
      style={{
        backgroundColor: KNISTR_YELLOW,
        borderRadius: "50%",
        width: "16px",
        height: "16px",
        display: "inline-block",
        ...style, // Merge custom styles with default styles
      }}
    />
  );
};