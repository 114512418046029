import { KNISTR_GREEN } from "./ImsMaterialTheme";

interface GreenDotProps extends React.HTMLAttributes<HTMLDivElement> {}

export const GreenDot: React.FC<GreenDotProps> = ({ style, ...rest }) => {
  return (
    <div
      {...rest}
      style={{
        backgroundColor: KNISTR_GREEN,
        borderRadius: "50%",
        width: "16px",
        height: "16px",
        display: "inline-block",
        ...style, // Merge custom styles with default styles
      }}
    />
  );
};
