import { Paper, Step, StepLabel, Stepper, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UrlPathSegmentBuilder } from "../../../../api/url";
import { SegmentDto } from "../../../../store/models/segments/SegmentDto";
import MenuLayout from "../../../app/MenuLayout";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const CreateCustomSegments = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [segmentData, setSegmentData] = useState<SegmentDto | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isStep1Valid, setIsStep1Valid] = useState(false);
  const [isStep2Valid, setIsStep2Valid] = useState(false);
  const { t } = useTranslation();

  const steps = [t("segmentBuilder.csv.step1.title"), t("segmentBuilder.csv.step2.title"), t("segmentBuilder.csv.step3.title")];

  const theme = useTheme();
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep === 0 && !isStep1Valid) {
      return;
    }
    if (activeStep === 1 && !isStep2Valid) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(UrlPathSegmentBuilder + '?selection=fileUpload');
    } else if (activeStep === 2 && !selectedFile) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep2Next = (values: SegmentDto) => {
    setSegmentData(values);
    handleNext();
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Step1 onNext={handleStep2Next} onValidationChange={setIsStep1Valid}
                      initialValues={segmentData || undefined} onBack={handleBack} backButtonText={t("common.cancel")} />;
      case 1:
        return <Step2 onValidationChange={setIsStep2Valid} onFileSelect={setSelectedFile}
                      initialFile={selectedFile || undefined} onBack={handleBack} onNext={handleNext} />;
      case 2:
        return <Step3 onBack={handleBack} segmentData={segmentData || undefined} initialFile={selectedFile || undefined} />;
      default:
        return t("segmentsForm.unknownStep");
    }
  };

  return (
    <div>
      <MenuLayout headline={t("segmentBuilder.title")}>
        <Paper sx={{ p: theme.spacing(2), mb: theme.spacing(2), marginTop: theme.spacing(4) }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
        <div>
          {getStepContent(activeStep)}
        </div>
      </MenuLayout>
    </div>
  );
};

export default CreateCustomSegments; 