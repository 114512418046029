import InfoIcon from '@mui/icons-material/Info';
import { Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { Api } from "../../../../api/Api";
import { UrlPathSegment } from "../../../../api/url";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "../../../../store/actions/NotificationActions";
import { SegmentDto } from "../../../../store/models/segments/SegmentDto";
import ImsPaperHead from "../../../atoms/ImsPaperHead";

interface Step3Props {
  onBack: () => void;
  segmentData?: SegmentDto;
  initialFile?: File;
}

const Step3 = ({ onBack, segmentData, initialFile, thunkCreateSuccessNotification, thunkCreateErrorNotification }: Step3Props & ConnectedProps<typeof connector>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    if (initialFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = e.target?.result as string;
          const lines = content.split('\n').filter(line => line.trim());
          setMemberCount(lines.length);
        } catch (err) {
          setMemberCount(0);
        }
      };
      reader.readAsText(initialFile);
    }
  }, [initialFile]);

  const handleFinish = async (segmentData: SegmentDto, initialFile: File) => {
    await Api.createSegmentWithFile(segmentData, initialFile)
    .then(() => {
      thunkCreateSuccessNotification(t("segmentBuilder.csv.step1.segmentCreated"));
      navigate(UrlPathSegment);
    })
    .catch((e) => {
      thunkCreateErrorNotification(e.message, e);
    });
  };

  return (
    <>
      <Paper style={{ padding: theme.spacing(3), marginTop: theme.spacing(6) }}>
        <ImsPaperHead text={t("segmentBuilder.csv.step3.title")} />
        
        <Grid container spacing={4} style={{ marginTop: theme.spacing(4) }}>
          <Grid item sm={12}>
            <Typography variant="h5">{t("segmentBuilder.csv.step3.numberOfMembers")}</Typography>
            <Typography variant="h6">{memberCount}</Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h5" sx={{ mb: 2 }}>{t("segmentBuilder.csv.step3.segmentDetails")}</Typography>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <Typography variant="body2">{t("segmentBuilder.csv.step3.segmentName")}:</Typography>
              </Grid>
              <Grid item sm={9}>
                <Typography variant="body2">{segmentData?.name}</Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="body2">{t("segmentBuilder.csv.step3.segmentId")}:</Typography>
              </Grid>
              <Grid item sm={9}>
                <Typography variant="body2">{segmentData?.externalSegmentId}</Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant="body2">{t("segmentBuilder.csv.step3.dataSource")}:</Typography>
              </Grid>
              <Grid item sm={9}>
                <Typography variant="body2">{initialFile?.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          backgroundColor: '#f0f7ff', 
          padding: theme.spacing(2),
          marginTop: theme.spacing(3),
          borderRadius: '4px'
        }}>
          <InfoIcon color="primary" style={{ marginRight: theme.spacing(1) }} />
          <Typography variant="body2" color="textSecondary">
            {t("segmentBuilder.csv.step3.infoBox")}
          </Typography>
        </div>
      </Paper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          color="secondary"
          variant="contained"
          style={{ margin: theme.spacing(2, 0, 2, 0), minWidth: 150 }}
          onClick={onBack}
        >
          {t("common.back")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: theme.spacing(2, 0, 2, 0), minWidth: 150 }}
          onClick={() => segmentData && initialFile && handleFinish(segmentData, initialFile)}
        >
          {t("segmentBuilder.csv.step3.buttonCreateSegment")}
        </Button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateSuccessNotification,
      thunkCreateErrorNotification,
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);

export default connector(Step3);
