import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { UrlPathSegmentDetail } from "../../api/url";
import { canMaintainSegments } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { PageViewMode } from "../../store/models/PageViewMode";
import { useTranslation } from "react-i18next";
import { SegmentDto } from "../../store/models/segments/SegmentDto";
import { Api } from "../../api/Api";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "../../store/actions/NotificationActions";
import KnistrConfirmDialog from "../atoms/KnistrConfirmDialog";

const canDelete = (segment: SegmentDto) => {
  if (!canMaintainSegments()) return false;
  return true;
};

const canEdit = (segment: SegmentDto) => {
  if (!canMaintainSegments()) return false;
  return true;
};

interface SegmentMenuProps {
  segment: SegmentDto;
  onSegmentDeleted: () => void;
}

const SegmentMenu = (props: SegmentMenuProps & ThunkProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };
  const canEditSegment = canEdit(props.segment);
  const canDeleteSegment = canDelete(props.segment);
  const { t } = useTranslation();

  const deleteSegment = () => {
    Api.deleteSegment(props.segment)
      .then(() => {
        props.thunkCreateSuccessNotification(t("segmentsPage.messages.segmentDeleted"));
        props.onSegmentDeleted();
      })
      .catch((e) => {
        props.thunkCreateErrorNotification(e.message, e);
      });
  };

  return (
    <div>
      {confirmVisible && (
        <KnistrConfirmDialog
          title={t("segmentsPage.confirmDeleteTitle")}
          description={t("segmentsPage.confirmDeleteDescription", { name: props.segment.name })}
          cancelButton={t("common.cancel")}
          confirmButton={t("common.delete")}
          onConfirm={() => {
            deleteSegment();
          }}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {canDeleteSegment && (
          <MenuItem
            onClick={(e) => {
              setConfirmVisible(true);
              handleClose(e);
            }}
          >
            {t("segmentsPage.segmentMenu.delete")}
          </MenuItem>
        )}
        {canEditSegment && (
          <MenuItem onClick={(e) => handleClose(e)}>
            <Link
              component={RouterLink}
              to={UrlPathSegmentDetail.replace(":id", props.segment.externalSegmentId).replace(
                ":viewMode",
                PageViewMode.EDIT
              )}
            >
              {t("segmentsPage.segmentMenu.edit")}
            </Link>
          </MenuItem>
        )}

        <MenuItem onClick={(e) => handleClose(e)}>
          <Link
            component={RouterLink}
            to={UrlPathSegmentDetail.replace(":id", props.segment.externalSegmentId).replace(
              ":viewMode",
              PageViewMode.VIEW
            )}
          >
            {t("segmentsPage.segmentMenu.show")}
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ thunkCreateSuccessNotification, thunkCreateErrorNotification }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(SegmentMenu);
