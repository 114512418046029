import { Button, Divider, Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathPassMedium } from "../../../api/url";
import { IStore } from "../../../store/IStore";
import { PassMediumDto, PassMediumType, passMediumTypes } from "../../../store/models/passmedium/PassMediumDto";
import { FormInput } from "../../atoms/FormInput";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import { isBlank, nameof, useMountEffect, ValidationErrors } from "../../atoms/Utils";
import { FormSelect } from "../../atoms/FormSelect";
import {
  thunkCreatePassMedium,
  thunkGetPassMediumByNumber,
  thunkUpdatePassMedium,
} from "../../../store/actions/PassMediumAction";
import { FormCheckbox } from "../../atoms/FormCheckbox";
import { FormRadioButton } from "../../atoms/FormRadioButton";
import { passIdViewOptions } from "./PassIdView";
import { Help } from "@mui/icons-material";
import { thunkCreateErrorNotification } from "../../../store/actions/NotificationActions";
import { BackdropProcessing } from "../../app/BackdropProcessing";
import ImageUpload from "./ImageUpload";
import FormColor from "../../atoms/FormColor";
import { useTranslation } from "react-i18next";

interface PassMediumFormProps extends ThunkProps {
  passMedium: PassMediumDto;
}

const loadImageFromUrl = async (fileName: string | undefined, url: string | undefined): Promise<File> => {
  if (!fileName || !url) {
    return new File([], "");
  }
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

const PassMediumForm = (props: PassMediumFormProps) => {
  const theme = useTheme();
  const { passMedium } = props;
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState<boolean>(true);
  const [updatedPassMedium, setUpdatedPassMedium] = useState<PassMediumDto>(passMedium);
  const { t } = useTranslation();

  useMountEffect(() => {
    const fetchPassMedium = async () => {
      if (!passMedium.passMediumNumber) {
        setProcessing(false);
        return;
      }

      try {
        const newPassMedium = {
          ...updatedPassMedium,
          stripImageFile: await loadImageAndReadData(passMedium.imageNameStrip, passMedium.imageLinkStrip),
          logoImageFile: await loadImageAndReadData(passMedium.imageNameLogo, passMedium.imageLinkLogo),
          iconImageFile: await loadImageAndReadData(passMedium.imageNameIcon, passMedium.imageLinkIcon),
        };

        setUpdatedPassMedium(newPassMedium);
      } catch (error) {
        props.thunkCreateErrorNotification("Error", t("messages.error.setup.imageDisplayError"));
        setError(true);
      } finally {
        setProcessing(false);
      }
    };

    fetchPassMedium();
  });

  const loadImageAndReadData = async (imageName: string | null | undefined, imageLink: string | null | undefined) => {
    if (!imageName || !imageLink) {
      return;
    }

    try {
      return await loadImageFromUrl(imageName, imageLink);
    } catch (error) {
      console.log(`Error loading image ${imageName}:`, error);
    }
  };

  const savePassMedium = async (passMediumToSave: PassMediumDto) => {
    let success;
    if (passMediumToSave.passMediumNumber) {
      if (passMediumToSave.type !== PassMediumType.PKPASS) {
        passMediumToSave = {
          ...passMediumToSave,
          programName: "",
          logoText: "",
          backgroundColor: "",
          foregroundColor: "",
          labelColor: "",
          stripImageFile: undefined,
          logoImageFile: undefined,
          iconImageFile: undefined,
        };
      }
      success = await props.thunkUpdatePassMedium(passMediumToSave, passMediumToSave.passMediumNumber as string);
    } else {
      if (passMediumToSave.type !== PassMediumType.PKPASS) {
        passMediumToSave = {
          ...passMediumToSave,
          programName: "",
          logoText: "",
          backgroundColor: "",
          foregroundColor: "",
          labelColor: "",
          stripImageFile: undefined,
          logoImageFile: undefined,
          iconImageFile: undefined,
        };
      }
      success = await props.thunkCreatePassMedium(passMediumToSave);
    }
    if (success) {
      setRedirect(true);
      setProcessing(false);
    }
  };

  if (redirect || error) {
    return <Navigate to={UrlPathPassMedium} />;
  }

  const validateMotive = (values: PassMediumDto, errors: ValidationErrors<PassMediumDto>) => {
    if (isBlank(values.motiv)) {
      errors.motiv = t("messages.validation.motiveMandatory");
    }
  };

  const validatePKPASS = (values: PassMediumDto, errors: ValidationErrors<PassMediumDto>) => {
    if (isBlank(values.backgroundColor)) errors.backgroundColor = t("messages.validation.backgroundColorMandatory");
    if (isBlank(values.foregroundColor)) errors.foregroundColor = t("messages.validation.fontColorMandatory");
    if (isBlank(values.labelColor)) errors.labelColor = t("messages.validation.labelColorMandatory");
    if (isBlank(values.barcodeFormat)) errors.barcodeFormat = t("messages.validation.barcodeFormatMandatory");
    if (!values.iconImageFile) errors.iconImageFile = t("messages.validation.iconFileMandatory");
  };

  const validateForm = (values: PassMediumDto) => {
    const errors: ValidationErrors<PassMediumDto> = {};
    validateMotive(values, errors);
    if (values.type === PassMediumType.PKPASS) {
      validatePKPASS(values, errors);
    }
    return errors;
  };

  return (
    <Form
      onSubmit={savePassMedium}
      initialValues={updatedPassMedium}
      validate={validateForm}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Paper style={{ padding: theme.spacing(3), marginTop: theme.spacing(6) }}>
            <BackdropProcessing processing={processing}></BackdropProcessing>
            <ImsPaperHead text={t("setup.headlinePassMediumDetails")} />
            <Grid container spacing={3}>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth
                    type="text"
                    name={nameof<PassMediumDto>("motiv")}
                    label={t("setup.motive")}
                    readOnly={!!passMedium.passMediumNumber}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormSelect
                    name={nameof<PassMediumDto>("type")}
                    label={t("setup.format")}
                    options={passMediumTypes}
                    fullWidth
                    readOnly={!!passMedium.passMediumNumber}
                  />
                </Grid>
              </Grid>
              {values.type === PassMediumType.PKPASS && (
                <>
                  <Grid item sm={12}>
                    <Typography variant="h6" gutterBottom>
                      {t("setup.pkpassContentDetails")}
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item sm={4}>
                      <FormInput
                        variant="outlined"
                        fullWidth
                        type="text"
                        name={nameof<PassMediumDto>("programName")}
                        label={t("setup.programName")}
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <Tooltip
                        data-testid="programmNameTooltip"
                        title={t("setup.infoProgramName")}
                        placement="right-start"
                        style={{ marginTop: 16 }}
                      >
                        <Help color="primary" />
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item sm={4}>
                      <FormInput
                        variant="outlined"
                        fullWidth
                        type="text"
                        name={nameof<PassMediumDto>("logoText")}
                        label={t("setup.logoText")}
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <Tooltip
                        data-testid="logoTextTooltip"
                        title={t("setup.infoLogoText")}
                        placement="right-start"
                        style={{ marginTop: 16 }}
                      >
                        <Help color="primary" />
                      </Tooltip>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item sm={3}>
                        <FormCheckbox
                          name={nameof<PassMediumDto>("includeName")}
                          label={t("setup.nameLoyaltyMember")}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item sm={3}>
                        <FormCheckbox
                          name={nameof<PassMediumDto>("includeExternalConnectionId")}
                          label={t("setup.customerOrPrepaidAccountNumber")}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item sm={3}>
                        <FormCheckbox
                          name={nameof<PassMediumDto>("includePassId")}
                          label={t("setup.passId")}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item sm={12}>
                        <Typography variant="h6" gutterBottom>
                          {t("setup.passIdDisplay")}
                        </Typography>
                        <Divider sx={{ marginBottom: 2 }} />
                        <FormRadioButton
                          name={nameof<PassMediumDto>("barcodeFormat")}
                          options={passIdViewOptions}
                          value={values.barcodeFormat}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography variant="h6" gutterBottom>
                      {t("setup.pkpassLayoutDetails")}
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                  </Grid>
                  <Grid container direction="row" margin={2}>
                    <Grid item xs={4}>
                      <FormColor
                        name={nameof<PassMediumDto>("backgroundColor")}
                        label={t("setup.backgroundColor")}
                        tooltipText={t("setup.infoBackgroundColor")}
                        dataTestId="backgroundColorTooltip"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormColor
                        name={nameof<PassMediumDto>("foregroundColor")}
                        label={t("setup.fontColor")}
                        tooltipText={t("setup.infoFontColor")}
                        dataTestId="fontColorTooltip"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormColor
                        name={nameof<PassMediumDto>("labelColor")}
                        label={t("setup.labelColor")}
                        tooltipText={t("setup.infoLabelColor")}
                        dataTestId="labelColorTooltip"
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" margin={2}>
                    <Grid item xs={4}>
                      <ImageUpload
                        name={nameof<PassMediumDto>("stripImageFile")}
                        label={t("setup.backgroundImage")}
                        tooltipText={t("setup.infoBackgroundImage")}
                        buttonText={t("setup.buttonUploadImage")}
                        processing={processing}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <ImageUpload
                        name={nameof<PassMediumDto>("logoImageFile")}
                        label={t("setup.logo")}
                        tooltipText={t("setup.infoLogo")}
                        buttonText={t("setup.buttonUploadLogo")}
                        processing={processing}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <ImageUpload
                        name={nameof<PassMediumDto>("iconImageFile")}
                        label={t("setup.icon")}
                        tooltipText={t("setup.infoIcon")}
                        buttonText={t("setup.buttonUploadIcon")}
                        processing={processing}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("setup.buttonCancel")}
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("setup.buttonSave")}
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  passMediums: state.passMediums.passMediums,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdatePassMedium,
      thunkCreatePassMedium,
      thunkCreateErrorNotification,
      thunkGetPassMediumByNumber,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PassMediumForm);
