import { Brightness1, Launch, PersonAdd, Search } from "@mui/icons-material";
import { Button, Grid, Link, MenuItem, Paper, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink, Navigate, useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerDetails } from "../../api/url";
import { thunkClearCustomerSearch, thunkCustomerSearch } from "../../store/actions/CustomerCareActions";
import {
  thunkGetFamilyByCustomerNumber,
  thunkTransferFamilyMember,
  thunkUpdateFamilyMembership,
} from "../../store/actions/FamilyActions";
import { IStore } from "../../store/IStore";
import { CustomerDto } from "../../store/models/customer/CustomerDto";
import { CustomerSearchDto } from "../../store/models/customer/CustomerSearchDto";
import { getCustomerStatusColor } from "../../store/models/customer/CustomerStatusDto";
import { FamilyRoleDto, useMemberRoleOptions } from "../../store/models/family/FamilyMembershipDto";
import MenuLayout from "../app/MenuLayout";
import { DateTag } from "../atoms/DateTag";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import { COLOR_BLACK } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { nameof, useMountEffect } from "../atoms/Utils";
import { getFamilyMemberName } from "./Family";
import FamilyInvitation from "./FamilyInvitation";
import { FamilyMembersDto } from "../../store/models/family/FamilyMembersDto";
import { useTranslation } from "react-i18next";
import KnistrConfirmDialog from "../atoms/KnistrConfirmDialog";

const FamilyPage = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [invitationVisible, setInvitationVisible] = useState(false);
  const [transferFamilyMemberVisible, setTransferFamilyMemberVisible] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const { id } = useParams<"id">();
  const { family, customers } = props;
  const memberRoleOptions = useMemberRoleOptions();

  useMountEffect(() => {
    props.thunkClearCustomerSearch();
  });

  const customerSearch = (customerSearch: CustomerSearchDto) => {
    props.thunkCustomerSearch(customerSearch);
  };

  const handleTransferFamilyMember = (customerNumber: string) => {
    setTransferFamilyMemberVisible(true);
    setCustomerNumber(customerNumber);
  };

  const headCells: ImsHeadCell<CustomerDto>[] = [
    { val: (val) => val.status, node: "" },
    { val: (val) => val.externalCustomerId, node: t("familyPage.tableHeadCardNumber") },
    { val: (val) => val.firstName, node: t("familyPage.tableHeadFirstName") },
    { val: (val) => val.lastName, node: t("familyPage.tableHeadLastName") },
    { val: (val) => val.mailAddress, node: t("familyPage.tableHeadEmail") },
    { val: (val) => val.dateOfBirth, node: t("familyPage.tableHeadDateOfBirth") },
    { val: (val) => val.address?.zipCode, node: t("familyPage.tableHeadPostcode") },
    { val: (val) => val.address?.cityName, node: t("familyPage.tableHeadCity") },
  ];

  const rows: ImsTableRow<CustomerDto>[] | undefined = customers?.map((row) => ({
    value: row,
    nodes: [
      <Brightness1
        style={{
          fill: getCustomerStatusColor(row.status),
          paddingTop: 4,
        }}
      />,

      <MenuItem>
        <Link component={RouterLink} to={UrlPathCustomerDetails.replace(":id", row.customerNumber)}>
          {row.externalCustomerId}
        </Link>
      </MenuItem>,
      <b>{row.firstName}</b>,
      <b>{row.lastName}</b>,
      <a style={{ color: COLOR_BLACK, textDecoration: "none" }} href={`mailto:${row.mailAddress}`}>
        {row.mailAddress}
      </a>,
      <DateTag date={row.dateOfBirth} />,
      row.address ? row.address.zipCode : "",
      row.address ? row.address.cityName : "",
      <PersonAdd
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleTransferFamilyMember(row.customerNumber);
        }}
      />,
    ],
  }));

  if (!id) return null;
  if (redirect) {
    return <Navigate to={UrlPathCustomerDetails.replace(":id", id)} />;
  }

  if (!family || !family.familyMembers.some((member) => member.customerNumber === id)) {
    props.thunkGetFamilyByCustomerNumber(id);
    return null;
  }

  const initialValues: FamilyRoleDto = {};

  family.familyMembers.forEach((member) => {
    initialValues[member.customerNumber] = member.roleName || "";
  });

  return (
    <MenuLayout headline={t("familyPage.title")}>
      <Grid container justifyContent="flex-end">
        <Button startIcon="+" variant="contained" color="primary" onClick={() => setInvitationVisible(true)}>
          {t("familyPage.buttonInvite")}
        </Button>
      </Grid>
      {transferFamilyMemberVisible && (
        <KnistrConfirmDialog
          title={t("familyPage.confirmDialogTitle")}
          description={t("familyPage.confirmDialogDescription")}
          hint={t("familyPage.confirmDialogHint")}
          cancelButton={t("common.cancel")}
          confirmButton={t("familyPage.confirmDialogButton")}
          onConfirm={async () => {
            let result = await props.thunkTransferFamilyMember(family.familyNumber, customerNumber);
            if (result) {
              window.location.reload();
            }
          }}
          setConfirmVisible={(confirmVisible) => {
            setTransferFamilyMemberVisible(confirmVisible);
          }}
        />
      )}
      {invitationVisible && <FamilyInvitation closeInvitation={() => setInvitationVisible(false)} />}
      <Form
        onSubmit={async (familyRoles: FamilyRoleDto) => {
          const updateRequests = Object.entries(familyRoles).map(([customerNumber, roleName]) => ({
            customerNumber,
            roleName,
          }));
          const familyList: FamilyMembersDto = {
            familyMembershipDto: updateRequests,
          };
          await props.thunkUpdateFamilyMembership(family.familyNumber, familyList);

          const anyFamilyMemberCustomerNumber = family.familyMembers[0].customerNumber;
          props.thunkGetFamilyByCustomerNumber(anyFamilyMemberCustomerNumber);
          setRedirect(true);
        }}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 6, 3),
                  margin: theme.spacing(6, 0, 0, 3),
                }}
              >
                <ImsPaperHead text={t("familyPage.headlineMember")} style={{ marginBottom: theme.spacing(4) }} />
                <Grid container spacing={4}>
                  {family.familyMembers.map((member) => (
                    <React.Fragment key={member.customerNumber}>
                      <Grid item md={4} style={{ marginTop: theme.spacing(2) }}>
                        <span
                          style={{
                            paddingRight: theme.spacing(1),
                            fontWeight: member.customerNumber === id ? "bold" : undefined,
                          }}
                        >
                          {getFamilyMemberName(member)}
                        </span>
                        {member.customerDto && (
                          <Link
                            component={RouterLink}
                            to={UrlPathCustomerDetails.replace(":id", member.customerNumber)}
                          >
                            <Launch style={{ marginBottom: theme.spacing(-1) }} />
                          </Link>
                        )}
                      </Grid>
                      <Grid item md={7}>
                        <FormSelect
                          name={member.customerNumber}
                          fullWidth={true}
                          label=""
                          options={memberRoleOptions}
                          testId="member-role"
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Paper>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("common.save")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
      <Form
        onSubmit={customerSearch}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 6, 3),
                  margin: theme.spacing(6, 0, 0, 3),
                }}
              >
                <ImsPaperHead
                  text={t("familyPage.headlineAddFamilyMember")}
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Grid container spacing={4}>
                  <Grid container spacing={2} item>
                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>{t("familyPage.labelCardNumber")}</b>
                      </Grid>
                      <Grid item md={8}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("externalCustomerId")}
                          label={t("familyPage.placeholderCardNumber")}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>Name</b>
                      </Grid>
                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("firstName")}
                          label={t("familyPage.placeholderFirstName")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("lastName")}
                          label={t("familyPage.placeholderLastName")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormDate
                          name={nameof<CustomerSearchDto>("dateOfBirth")}
                          label={t("familyPage.placeholderDateOfBirth")}
                          fullWidth={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid
                container
                style={{
                  marginBottom: theme.spacing(3),
                  marginTop: theme.spacing(2),
                }}
                justifyContent="flex-end"
              >
                <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                  <Search />
                  {t("familyPage.buttonSearch")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
      {rows &&
        (rows.length ? (
          <ImsTable hasMenu={true} headCells={headCells} rows={rows} sortColumnIndex={1} />
        ) : (
          <p>{t("familyPage.tableNoDataFound")}</p>
        ))}
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  family: state.customerCare.family,
  customers: state.customerCare.customers,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkGetFamilyByCustomerNumber,
      thunkUpdateFamilyMembership,
      thunkTransferFamilyMember,
      thunkCustomerSearch,
      thunkClearCustomerSearch,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FamilyPage);
