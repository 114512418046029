import { useState, useEffect, useCallback } from "react";
import { Autocomplete, CircularProgress, TextField, Chip, Box } from "@mui/material";
import _ from "lodash"; // Import Lodash
import { Api } from "../../api/Api";
import { SegmentDto } from "../../store/models/segments/SegmentDto";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { Field } from "react-final-form";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import CloseIcon from "@mui/icons-material/Close";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";
import { useTranslation } from "react-i18next";
interface SegmentSelectProps {
  selectedValues?: SegmentDto[];
}

interface FormInputProps {
  name: string;
  label: string;
  readOnly?: boolean;
  variant?: string;
  fullWidth?: boolean;
  prefix?: string;
  helperText?: string;
  tooltip?: string;
  testId?: string;
  multiline?: boolean;
  autoComplete?: string;
  fieldConfig?: FieldConfigDto[];
  rows?: number;
}

const SegmentSelect = (props: SegmentSelectProps & FormInputProps & ThunkProps) => {
  const [options, setOptions] = useState<SegmentDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const { t } = useTranslation();
  // Fetch data from API
  const fetchData = async (query: string) => {
    Api.getPaginatedSegments(query, 1, 100, "name", "asc")
      .then((data) => updateOptions(data.segments))
      .catch((error) => props.thunkCreateErrorNotification(t("segmentsPage.messages.errorLoadingSegments"), error))
      .finally(() => setLoading(false));
  };

  // Use Lodash debounce to delay API calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(_.debounce(fetchData, 500), []);

  // Call debounced API when inputValue changes
  useEffect(() => {
    debouncedFetch(inputValue);
  }, [inputValue, debouncedFetch]);

  const updateOptions = (newOptions: SegmentDto[]) => {
    setOptions((prevOptions) => {
      // Merge previous and new options
      const mergedOptions = [...prevOptions, ...newOptions];

      // Remove duplicates based on `externalSegmentId`
      const uniqueOptions = mergedOptions.filter(
        (option, index, self) => index === self.findIndex((o) => o.externalSegmentId === option.externalSegmentId)
      );

      return uniqueOptions;
    });
  };
  
  const getOptionName = (option: string) => {
    return options.filter((x) => x.externalSegmentId === option)[0]?.name || option;
  };

  return (
    <Field<string[]> name={props.name} type="text">
      {({ input, meta }) => {
        return (
          <Autocomplete
            multiple
            readOnly={props.readOnly}
            options={options.map((option) => option.externalSegmentId)}
            getOptionLabel={getOptionName}
            // filterOptions={(x) => x} // Disable client-side filtering
            isOptionEqualToValue={(option, value) => option === value}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
            onChange={(_, newSelected) => {
              input.onChange(newSelected.map((newValue) => newValue));
            }}
            value={input.value}
            loading={loading}
            // ✅ Custom dropdown rendering
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option}>
                {getOptionName(option)}
                <span style={{ width: "10px" }} />
                <span style={{ fontStyle: "italic", fontSize: "12px", color: "gray" }}>{option}</span>
              </Box>
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                return (
                  <Chip
                    label={getOptionName(option)}
                    sx={{ marginLeft: "5px", marginRight: "5px" }}
                    {...getTagProps({ index })}
                    deleteIcon={props.readOnly ? undefined : <CloseIcon />}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label}
                variant="outlined"
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
                }}
              />
            )}
          />
        );
      }}
    </Field>
  );
};

const mapStateToProps = (_: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      thunkCreateErrorNotification,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(SegmentSelect);
