import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { OptionHTMLAttributes, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded } from "./FieldValidation";
import { COLOR_WHITE } from "./ImsMaterialTheme";

interface FormSelectProps {
  name: string;
  label: string;
  options: OptionHTMLAttributes<HTMLOptionElement>[];
  fullWidth?: boolean;
  testId?: string;
  readOnly?: boolean;
  multiple?: boolean;
  fieldConfig?: FieldConfigDto[];
  hierarchyLogic?: boolean;
  initialValues?: string[];
  helperText?: string;
  onChange?: (value: any) => void; // Neue onChange-Eigenschaft
}

export const FormSelect = ({
  name,
  label,
  options,
  fullWidth,
  testId,
  readOnly,
  multiple,
  fieldConfig,
  hierarchyLogic,
  initialValues,
  helperText,
  onChange, // Neue onChange-Eigenschaft
}: FormSelectProps) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (!readOnly && hierarchyLogic && initialValues !== undefined && initialValues && initialValues.length > 0) {
      const valueSelected = initialValues[0];
      const initIndex = options.filter((option) => option.value === valueSelected)[0]?.tabIndex;
      if (initIndex !== undefined && initIndex >= 0) {
        setSelectedIndex(initIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  if (fieldConfig && !isFieldNeeded(name, fieldConfig)) return null;

  const handleSelectedValue = (currentSelectedValues: string[], selectedValue: any, tabIndex: number | undefined) => {
    if (!hierarchyLogic || tabIndex === undefined) {
      return;
    }
    if (currentSelectedValues.includes(selectedValue) && currentSelectedValues.length === 1) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(tabIndex);
    }
  };

  return (
    <Field name={name} type={multiple ? "field" : "select"}>
      {({ input, meta }) => {
        const hasErrors = Boolean(meta.error && meta.touched);
        const helper = helperText ?? meta.error;

        return (
          <FormControl
            style={{ marginTop: "8px" }}
            data-testid={testId ?? "formselect-" + name}
            variant="outlined"
            fullWidth={fullWidth}
            error={hasErrors}
          >
            <InputLabel id={"lbl-select-" + name} style={{ backgroundColor: COLOR_WHITE }}>
              {label}
            </InputLabel>
            <Select
              {...input}
              multiple={multiple}
              disabled={readOnly}
              id={"select-" + name}
              inputProps={{ "aria-labelledby": "lbl-select-" + name }}
              variant="outlined"
              style={{ minWidth: 200 }}
              error={hasErrors}
              onChange={(event) => {
                input.onChange(event.target.value); // Setze den Wert im Form-Context
                if (onChange) {
                  onChange(event.target.value); // Rufe die onChange-Eigenschaft auf
                }
              }}
            >
              {options.map(({ label, value, style, tabIndex }) => (
                <MenuItem
                  key={"selectKey" + value}
                  value={value}
                  style={style}
                  disabled={
                    (hierarchyLogic && selectedIndex >= 0 && tabIndex !== selectedIndex) ||
                    (tabIndex === undefined && selectedIndex >= 0)
                  }
                  onClick={() => {
                    handleSelectedValue(input.value, value, tabIndex);
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
            {(hasErrors || helper) && <FormHelperText>{helper}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};
