import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathPassMediumEdit } from "../../../api/url";
import { IStore } from "../../../store/IStore";
import { PassMediumDto } from "../../../store/models/passmedium/PassMediumDto";
import { canReadIdentificationTypes } from "../../../store/actions/LoginActions";
import { thunkDeletePassMedium } from "../../../store/actions/PassMediumAction";
import { useTranslation } from "react-i18next";
import KnistrConfirmDialog from "../../atoms/KnistrConfirmDialog";

interface PassMediumTableMenuProps {
  passMedium: PassMediumDto;
}

function PassMediumTableMenu(props: PassMediumTableMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  const { t } = useTranslation();
  if (!canReadIdentificationTypes()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  return (
    <div>
      {confirmVisible && (
        <KnistrConfirmDialog
          title={t("setup.deletePassMediumTitle")}
          description={t("setup.deletePassMediumDescription", {name: props.passMedium.motiv})}
          cancelButton={t("common.cancel")}
          confirmButton={t("common.delete")}
          onConfirm={() => props.thunkDeletePassMedium(props.passMedium)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link component={RouterLink} to={UrlPathPassMediumEdit.replace(":id", props.passMedium.passMediumNumber!)}>
            {t("setup.kebabEdit")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setConfirmVisible(true);
            handleClose(e);
          }}
        >
          {t("setup.kebabDelete")}
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkDeletePassMedium }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PassMediumTableMenu);
