import { Help } from "@mui/icons-material";
import { Button, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { bindActionCreators, Dispatch } from "redux";
import { Api } from "../../api/Api";
import { UrlPathSegment } from "../../api/url";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "../../store/actions/NotificationActions";
import { IStore } from "../../store/IStore";
import { SegmentDto } from "../../store/models/segments/SegmentDto";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { FormInput } from "../atoms/FormInput";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../atoms/Utils";

interface SegmentFormProps {
  segment: SegmentDto;
}

const SegmentForm = (props: SegmentFormProps & ThunkProps) => {
  const { segment } = props;
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();

  const saveSegment = (segmentToSave: SegmentDto) => {
    let success;
    if (segmentToSave.externalSegmentId && segment?.externalSegmentId) {
      Api.updateSegment(segmentToSave)
        .then(() => {
          props.thunkCreateSuccessNotification(t("segmentsForm.segmentUpdated"));
          setRedirect(true);
        })
        .catch((e) => {
          props.thunkCreateErrorNotification(e.message, e);
        });
    } else {
      Api.createSegment(segmentToSave)
        .then(() => {
          props.thunkCreateSuccessNotification(t("segmentsForm.segmentCreated"));
          setRedirect(true);
        })
        .catch((e) => {
          props.thunkCreateErrorNotification(e.message, e);
        });
    }
    if (success) {
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Navigate to={UrlPathSegment} />;
  }

  const validateForm = (values: SegmentDto) => {
    const errors: ValidationErrors<SegmentDto> = {};
    if (isBlank(values.name)) {
      errors.name = t("messages.validation.segmentNameMandatory");
    }
    if (!values.externalSegmentId) {
      errors.externalSegmentId = t("messages.validation.segmentExternalNumberMandatory");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={saveSegment}
      initialValues={segment || {}}
      validate={validateForm}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead text={t("segmentsForm.headlineDetails")} />

            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="name"
                    label={t("segmentsForm.segmentName")}
                  />
                </Grid>
                <Grid item sm={8}></Grid>

                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth
                    type="text"
                    name={nameof<SegmentDto>("externalSegmentId")}
                    label={t("segmentsForm.segmentId")}
                    readOnly={!!segment?.externalSegmentId}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Tooltip title={t("segmentsForm.segmentIdInfoBox")} placement="right-start" style={{ marginTop: 16 }}>
                    <Help color="primary" />
                  </Tooltip>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item sm={12}>
                    <FormCheckbox name="defaultSegment" label={t("segmentsForm.assignOnRegistration")} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("segmentsForm.buttonCancel")}
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("segmentsForm.buttonSave")}
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ thunkCreateSuccessNotification, thunkCreateErrorNotification }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;

export default connector(SegmentForm);
