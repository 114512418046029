import Keycloak from "keycloak-js";
import { getTenant } from "./api/TenantUtils";

const keycloak = new Keycloak({
  url: window.env.REACT_APP_KEYCLOAK_URL || process.env.REACT_APP_KEYCLOAK_URL,
  realm: getTenant(),
  clientId: "react-admin",
});

keycloak.onTokenExpired = () => {
  console.debug("Token expired");
  keycloak.updateToken(30).catch(() => {
    keycloak.logout();
  });
};

export default keycloak;
