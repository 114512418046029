export const UrlAppPrefix = "/loyalty/";
export const UrlPathMain = (window.env.PUBLIC_URL || process.env.PUBLIC_URL) + UrlAppPrefix;

export const TabCircle = "circle";
export const TabFlow = "flow";
export const TabPass = "pass";
export const TabDSGVO = "dsgvo";
export const TabFilter = "receiptdetail";
export const TabCustomField = "customfield";
export const TabPassMedium = "passmedium";

export const TabLinks = "links";
export const TabCarousel = "carousels";
export const TabSplash = "splash";

export const UrlPathReports = UrlPathMain + "report";
export const UrlPathPreferences = UrlPathMain + "preferences";
export const UrlPathPreferencesCreate = UrlPathPreferences + "/create";
export const UrlPathPreferencesEdit = UrlPathPreferences + "/:id/edit";
export const UrlPathBonus = UrlPathMain + "bonus";
export const UrlPathBonusCreate = UrlPathBonus + "/create";
export const UrlPathBonusViewMode = UrlPathBonus + "/:id/:viewMode/";
export const UrlPathBonusPremium = UrlPathMain + "bonuspremium";
export const UrlPathBonusPremiumCreate = UrlPathBonusPremium + "/create";
export const UrlPathBonusPremiumViewMode = UrlPathBonusPremium + "/:id/:viewMode";
export const UrlPathBonusXpress = UrlPathMain + "bonusxpress";
export const UrlPathAppContent = UrlPathMain + "appcontent";
export const UrlPathAppContentLinks = UrlPathAppContent + "/" + TabLinks;
export const UrlPathAppContentCarousel = UrlPathAppContent + "/" + TabCarousel;
export const UrlPathAppContentSplash = UrlPathAppContent + "/" + TabSplash;
export const UrlPathCustomerSearch = UrlPathMain + "customer";
export const UrlPathCustomer = UrlPathMain + "customer";
export const UrlPathCustomerEdit = UrlPathCustomer + "/:id/edit";
export const UrlPathCustomerDetails = UrlPathCustomer + "/:id/details";
export const UrlPathCustomerPreferences = UrlPathCustomer + "/:id/preferences";
export const UrlPathCampaigns = UrlPathMain + "campaign";
export const UrlPathCashback = UrlPathMain + "cashback";
export const UrlPathCampaignCreate = UrlPathCampaigns + "/create";
export const UrlPathCampaignEdit = UrlPathCampaigns + "/:id/edit";
export const UrlPathOperationalUnit = UrlPathMain + "operationalunit";
export const UrlPathOperationalUnitCreate = UrlPathOperationalUnit + "/create";
export const UrlPathAppContentCreate = UrlPathAppContentLinks + "/create";
export const UrlPathAppContentEdit = UrlPathAppContentLinks + "/:id/edit";
export const UrlPathCarouselCreate = UrlPathAppContentCarousel + "/create";
export const UrlPathCarouselEdit = UrlPathAppContentCarousel + "/:id/edit";
export const UrlPathOperationalUnitEdit = UrlPathOperationalUnit + "/:id/edit";
export const UrlPathUser = UrlPathMain + "user";
export const UrlPathUserCreate = UrlPathUser + "/create";
export const UrlPathUserEdit = UrlPathUser + "/:id/edit";
export const UrlPathRegistration = UrlPathCustomer + "/register";
export const UrlPathFamily = UrlPathMain + "family/:id";
export const UrlPathSetup = UrlPathMain + "setup";
export const UrlPathCircle = UrlPathSetup + "/" + TabCircle;
export const UrlPathCircleCreate = UrlPathCircle + "/create";
export const UrlPathCircleEdit = UrlPathCircle + "/:id/edit";
export const UrlPathIdentificationType = UrlPathSetup + "/" + TabPass;
export const UrlPathIdentificationTypeCreate = UrlPathIdentificationType + "/create";
export const UrlPathIdentificationTypeEdit = UrlPathIdentificationType + "/:id/edit";

export const UrlPathPassMedium = UrlPathSetup + "/" + TabPassMedium;
export const UrlPathPassMediumCreate = UrlPathPassMedium + "/create";
export const UrlPathPassMediumEdit = UrlPathPassMedium + "/:id/edit";

export const UrlPathFlow = UrlPathMain + TabFlow;
export const UrlPathFlowCreate = UrlPathFlow + "/create";
export const UrlPathFlowEdit = UrlPathFlow + "/:id/edit";
export const UrlPathGdpr = UrlPathSetup + "/" + TabDSGVO;
export const UrlPathInteractionSearch = UrlPathMain + "interactionsearch";
export const UrlPathReceiptDetail = UrlPathSetup + "/" + TabFilter;
export const UrlPathCustomField = UrlPathSetup + "/" + TabCustomField;
export const UrlPathConfigValueCreate = (url: string) => url + "/create";
export const UrlPathConfigValueEdit = (url: string) => url + "/:id/edit";
export const URlPathLanding = UrlPathMain + "landing";
export const URlPathPkPassDownload = UrlPathMain + "passes/download";
export const URlPathDownloadFile = UrlPathMain + "download-file";

export const UrlPathSegment = UrlPathMain + "segments";
export const UrlPathSegmentCreate = UrlPathSegment + "/create";
export const UrlPathSegmentDetail = UrlPathSegment + "/:id/:viewMode";
export const UrlPathSegmentBuilder = UrlPathSegment + "/builder";
export const UrlPathSegmentBuilderCsv = UrlPathSegmentBuilder + "/csv";

const publicPaths = [URlPathLanding, URlPathPkPassDownload];

export const isPublicPath = () => publicPaths.includes(window.location.pathname);
