import { Api } from "../../api/Api";
import { StringThunk } from "../IStore";
import { thunkCreateErrorNotification } from "./NotificationActions";

export const thunkDownloadFile = (
  type: string,
  id: string,
  fileName: string
): StringThunk => async (dispatch) => {
  try {
    return await Api.downloadFile(type, id, fileName);
  } catch (e) {
    dispatch(thunkCreateErrorNotification("Cannot download file", e));
    return "";
  }
};
