import { Button, Grid, Paper, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { UrlPathBonus } from "../../api/url";
import {
  thunkAddBonusRule,
  thunkAddBonusRuleWithFile,
  thunkUpdateBonusRule,
} from "../../store/actions/BonusRuleActions";
import { IStore } from "../../store/IStore";
import {
  BonusRuleDto,
  FilterDto,
  RuleFilterApplyType,
  RuleFilterCustomerPropertyType,
} from "../../store/models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../../store/models/bonusRule/BonusRuleStatus";
import { BonusRuleType } from "../../store/models/bonusRule/BonusRuleType";
import { BonusRuleValueType } from "../../store/models/bonusRule/BonusRuleValueType";
import {
  CustomerInteractionType,
  formatCustomerInteractionType,
  isCustomInteractionType,
} from "../../store/models/bonusRule/CustomerInteractionType";
import { RuleRoundingMode } from "../../store/models/bonusRule/RuleRoundingMode";
import { ConfigGroupKey } from "../../store/models/config/ConfigGroupKey";
import { PageViewMode } from "../../store/models/PageViewMode";
import { FormDateTime } from "../atoms/FormDateTime";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";
import Filter from "./Filter";
import { getCurrentDateAsString } from "../atoms/Utils";
import { PurchaseTotalCondition } from "../../store/models/bonusRule/PurchaseTotalCondition";
import { BackdropProcessing } from "../app/BackdropProcessing";
import { useState } from "react";
import { FrequencyMode } from "../../store/models/bonusRule/FrequencyMode";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import SegmentSelect from "../segments/SegmentSelect";
import { CustomerFilterType } from "../../store/models/bonusRule/CustomerFilterType";

export const getInteractionTypeOptions = (t: TFunction) => [
  {
    value: CustomerInteractionType.REGISTER,
    label: formatCustomerInteractionType(CustomerInteractionType.REGISTER, t),
  },
  {
    value: CustomerInteractionType.ADD_ADDRESS,
    label: t("historyFeed.interaction.addAddress"),
  },
  {
    value: CustomerInteractionType.FAMILY_INVITATION,
    label: formatCustomerInteractionType(CustomerInteractionType.FAMILY_INVITATION, t),
  },
  {
    value: CustomerInteractionType.FRIEND_INVITATION,
    label: formatCustomerInteractionType(CustomerInteractionType.FRIEND_INVITATION, t),
  },
  {
    value: CustomerInteractionType.ADD_PREFERENCES,
    label: formatCustomerInteractionType(CustomerInteractionType.ADD_PREFERENCES, t),
  },
  {
    value: CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED,
    label: formatCustomerInteractionType(CustomerInteractionType.EMAIL_CORRESPONDENCE_ALLOWED, t) + " " + t("given"),
  },
  {
    value: CustomerInteractionType.CUSTOM,
    label: t("historyFeed.interaction.custom"),
  },
];

interface BonusRuleFormModel extends BonusRuleDto {
  customInteraction?: string;
  bonusCircleNumbers?: string[];
  bonusCircleGroupNumber?: string;
  valueTypeOrRoundingMode?: BonusRuleValueType | RuleRoundingMode;
  customerFilterType?: CustomerFilterType;
  segments?: string[];
}

interface BonusRuleFormProps {
  bonusRule: BonusRuleFormModel;
  viewMode: PageViewMode;
}

const BonusRuleForm = (props: BonusRuleFormProps & ThunkProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState<boolean>(false);
  const [newRuleNumberInitial] = useState<string>(`NEW-RULE-${uuid()}`);
  const { t } = useTranslation();

  const interactionTypeOptions = getInteractionTypeOptions(t);

  const frequencyOptions = [
    { value: "", label: t("bonusRuleCreate.placeholderOption") },
    { value: FrequencyMode.DAY, label: t("bonusRuleCreate.day") },
    { value: FrequencyMode.WEEK, label: t("bonusRuleCreate.week") },
    { value: FrequencyMode.MONTH, label: t("bonusRuleCreate.month") },
  ];

  const typeOptions = [
    { value: BonusRuleType.TRANSACTION, label: t("bonusRuleCreate.purchase") },
    { value: BonusRuleType.INTERACTION, label: t("bonusRuleCreate.customerActivity") },
  ];

  const valueTypeOrRoundingModeOptions = [
    { value: BonusRuleValueType.FIX, label: t("bonusRuleCreate.roundingModeFix") },
    { value: RuleRoundingMode.UP, label: t("bonusRuleCreate.roundingModeUp") },
    { value: RuleRoundingMode.DOWN, label: t("bonusRuleCreate.roundingModeDown") },
    { value: RuleRoundingMode.MERCANTILE, label: t("bonusRuleCreate.roundingModeMercantile") },
    { value: BonusRuleValueType.PER_CENT, label: t("bonusRuleCreate.roundingModePerCent") },
  ];

  const purchaseTotalConditionOptions = [
    { value: "", label: t("bonusRuleCreate.placeholderOption") },
    { value: PurchaseTotalCondition.GREATER, label: t("bonusRuleCreate.greaterThan") },
    { value: PurchaseTotalCondition.LESS, label: t("bonusRuleCreate.smallerThan") },
    { value: PurchaseTotalCondition.EQUAL, label: t("bonusRuleCreate.equals") },
  ];

  const customerFilterOptions = [
    { value: CustomerFilterType.CIRCLES, label: t("bonusRuleCreate.circles") },
    { value: CustomerFilterType.SEGMENTS, label: t("bonusRuleCreate.segments") },
  ];

  const validateForm = (values: BonusRuleFormModel) => {
    const errors: ValidationErrors<BonusRuleFormModel> = {};
    if (isBlank(values.ruleName)) {
      errors.ruleName = t("messages.validation.required");
    }
    if (isBlank(values.value)) {
      errors.value = t("messages.validation.required");
    }
    if (!values.validFrom) {
      errors.validFrom = t("messages.validation.required");
    }
    if (values.type === BonusRuleType.TRANSACTION && !values.unitNumbers?.length) {
      errors.unitNumbers = t("messages.validation.required");
    }
    if (values.customerFilterType === CustomerFilterType.CIRCLES && !values.bonusCircleNumbers?.length) {
      errors.bonusCircleNumbers = t("messages.validation.required");
    }
    if (values.validFrom && values.validTill && values.validTill < values.validFrom) {
      errors.validTill = t("messages.validation.endDateBeforeStartDate");
    }
    if (values.type === BonusRuleType.INTERACTION && !values.customerInteractionType) {
      errors.customerInteractionType = t("messages.validation.required");
    }
    if (values.customerInteractionType === CustomerInteractionType.CUSTOM && !values.customInteraction) {
      errors.customInteraction = t("messages.validation.required");
    }

    return errors;
  };

  const saveRule = async (ruleModel: BonusRuleFormModel) => {
    setProcessing(true);
    const rule = prepareRuleForSave(ruleModel);

    if (rule.ruleNumber) {
      await updateRule(rule);
    } else {
      await createRule(rule);
    }
    setProcessing(false);
  };

  const prepareRuleForSave = (ruleModel: BonusRuleFormModel) => {
    let rule = _.cloneDeep(ruleModel);
    delete rule.customInteraction;
    rule.status = BonusRuleStatus.DISABLED;

    if (rule.customerFilterType === CustomerFilterType.SEGMENTS) {
      setBonusSegmentFilter(rule);
    } else {
      setBonusCircleFilter(rule);
    }
    setCustomerInteractionType(rule, ruleModel);
    setUnitNumber(rule);
    setValueTypeAndRoundingMode(rule);
    return rule;
  };

  const setBonusCircleFilter = (rule: BonusRuleFormModel) => {
    if (rule.bonusCircleNumbers?.length) {
      const bonusCircleRuleIndex = props.bonusRule.ruleFilters.findIndex(
        (filter) => filter.ruleFilterPropertyName === RuleFilterCustomerPropertyType.CIRCLE_NUMBER
      );

      if (bonusCircleRuleIndex !== -1) {
        rule.ruleFilters[bonusCircleRuleIndex].ruleFilterValues = rule.bonusCircleNumbers;
      } else {
        rule.ruleFilters.push(createNewBonusCircleFilter(rule.bonusCircleNumbers, rule.bonusCircleGroupNumber!));
      }

      delete rule.bonusCircleNumbers;
    }
  };

  const setBonusSegmentFilter = (rule: BonusRuleFormModel) => {
    if (rule.segments?.length) {
      const bonusSegmentRuleIndex = props.bonusRule.ruleFilters.findIndex(
        (filter) => filter.ruleFilterPropertyName === RuleFilterCustomerPropertyType.EXTERNAL_SEGMENT_ID
      );

      if (bonusSegmentRuleIndex !== -1) {
        rule.ruleFilters[bonusSegmentRuleIndex].ruleFilterValues = rule.segments;
      } else {
        rule.ruleFilters.push(createNewBonusSegmentFilter(rule.segments, rule.bonusCircleGroupNumber!));
      }

      delete rule.segments;
    }
  };

  const createNewBonusCircleFilter = (bonusCircleNumbers: string[], bonusCircleGroupNumber: string): FilterDto => {
    return {
      ruleFilterName: "CIRCLE-FILTER-" + uuid(),
      ruleFilterType: ConfigGroupKey.CUSTOMER_PROPERTY,
      ruleFilterPropertyName: RuleFilterCustomerPropertyType.CIRCLE_NUMBER,
      ruleFilterApplyType: RuleFilterApplyType.INCLUDE,
      ruleFilterValues: bonusCircleNumbers,
      ruleFilterPropertyGroupNumber: bonusCircleGroupNumber,
    };
  };

  const createNewBonusSegmentFilter = (bonusSegmentIds: string[], bonusSegmentGroupNumber: string): FilterDto => {
    return {
      ruleFilterName: "SEGMENT-FILTER-" + uuid(),
      ruleFilterType: ConfigGroupKey.CUSTOMER_PROPERTY,
      ruleFilterPropertyName: RuleFilterCustomerPropertyType.EXTERNAL_SEGMENT_ID,
      ruleFilterApplyType: RuleFilterApplyType.INCLUDE,
      ruleFilterValues: bonusSegmentIds,
      ruleFilterPropertyGroupNumber: bonusSegmentGroupNumber,
    };
  };

  const setCustomerInteractionType = (rule: BonusRuleFormModel, ruleModel: BonusRuleFormModel) => {
    if (rule.customerInteractionType === CustomerInteractionType.CUSTOM) {
      rule.customerInteractionType = ruleModel.customInteraction;
    }
  };

  const setUnitNumber = (rule: BonusRuleFormModel) => {
    if (rule.type === BonusRuleType.INTERACTION) {
      rule.unitNumbers = [
        props.operationalUnits.find((operationalUnit) => !operationalUnit.parentUnitNumber)!.unitNumber,
      ];
    }
  };

  const setValueTypeAndRoundingMode = (rule: BonusRuleFormModel) => {
    if (rule.valueTypeOrRoundingMode === BonusRuleValueType.FIX) {
      rule.valueType = BonusRuleValueType.FIX;
      rule.ruleRoundingMode = RuleRoundingMode.MERCANTILE;
    } else if (rule.valueTypeOrRoundingMode === BonusRuleValueType.PER_CENT) {
      rule.valueType = BonusRuleValueType.PER_CENT;
      rule.ruleRoundingMode = RuleRoundingMode.MERCANTILE;
    } else {
      rule.valueType = BonusRuleValueType.FACTOR;
      rule.ruleRoundingMode = rule.valueTypeOrRoundingMode as RuleRoundingMode;
    }

    delete rule.valueTypeOrRoundingMode;
  };

  const updateRule = async (rule: BonusRuleFormModel) => {
    rule.ruleFilters.forEach((filter) => {
      if (
        filter.ruleFilterValues &&
        filter.ruleFilterPropertyName !== RuleFilterCustomerPropertyType.CIRCLE_NUMBER &&
        filter.ruleFilterPropertyName !== RuleFilterCustomerPropertyType.EXTERNAL_SEGMENT_ID
      ) {
        filter.ruleFilterValues = [];
      }
    });

    const success = await props.thunkUpdateBonusRule(rule);
    if (success) {
      navigate(UrlPathBonus);
    }
  };

  const createRule = async (rule: BonusRuleFormModel) => {
    const formData = new FormData();
    rule.ruleFilters.forEach((filter) => {
      if (filter.ruleFilterValues) {
        const csvBlob = new Blob([...filter.ruleFilterValues.join("\n")], {
          type: "text/csv",
        });
        if (!filter.ruleFilterNumber) {
          filter.ruleFilterNumber = uuid();
        }
        formData.append("files", csvBlob, `${filter.ruleFilterNumber}.csv`);
        filter.ruleFilterValues = [];
      }
    });
    rule.ruleNumber = newRuleNumberInitial;
    formData.append("data", JSON.stringify(rule));
    const success = await props.thunkAddBonusRuleWithFile(formData);
    if (success) {
      navigate(UrlPathBonus);
    }
  };

  const circleOptions = props.circles.map((circle) => ({
    value: circle.circleNumber,
    label: circle.circleName,
  }));

  const { validFrom, status } = props.bonusRule;
  const isRunning = validFrom && validFrom < getCurrentDateAsString();
  const isViewMode = props.viewMode === PageViewMode.VIEW;
  const isEnabled = status === BonusRuleStatus.ENABLED;

  const readOnly = isViewMode || isEnabled || isRunning === true;

  props.bonusRule.ruleFilters.forEach((filter) => {
    filter.ruleFilterValues = filter.ruleFilterValues || [];
  });

  const bonusCircleRule = props.bonusRule.ruleFilters.find(
    (filter) => filter.ruleFilterPropertyName === RuleFilterCustomerPropertyType.CIRCLE_NUMBER
  );
  props.bonusRule.bonusCircleNumbers = bonusCircleRule?.ruleFilterValues || [];
  props.bonusRule.bonusCircleGroupNumber = bonusCircleRule?.ruleFilterPropertyGroupNumber;

  const bonusSegmentRule = props.bonusRule.ruleFilters.find(
    (filter) => filter.ruleFilterPropertyName === RuleFilterCustomerPropertyType.EXTERNAL_SEGMENT_ID
  );
  props.bonusRule.segments = bonusSegmentRule?.ruleFilterValues || [];
  props.bonusRule.bonusCircleGroupNumber = bonusSegmentRule?.ruleFilterPropertyGroupNumber;

  if (bonusSegmentRule) {
    props.bonusRule.customerFilterType = CustomerFilterType.SEGMENTS;
  }

  if (
    props.bonusRule.valueType === BonusRuleValueType.FIX ||
    props.bonusRule.valueType === BonusRuleValueType.PER_CENT
  ) {
    props.bonusRule.valueTypeOrRoundingMode = props.bonusRule.valueType;
  } else {
    props.bonusRule.valueTypeOrRoundingMode = props.bonusRule.ruleRoundingMode;
  }

  if (isCustomInteractionType(props.bonusRule.customerInteractionType)) {
    props.bonusRule.customInteraction = props.bonusRule.customerInteractionType;
    props.bonusRule.customerInteractionType = CustomerInteractionType.CUSTOM;
  }

  // default value for customerFilterType: CustomerFilterType.CIRCLES
  props.bonusRule.customerFilterType = props.bonusRule.customerFilterType || CustomerFilterType.CIRCLES;

  return (
    <>
      <BackdropProcessing processing={processing}></BackdropProcessing>
      <Form
        onSubmit={saveRule}
        initialValues={props.bonusRule}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => {
          if (values.type === BonusRuleType.INTERACTION) {
            values.valueTypeOrRoundingMode = BonusRuleValueType.FIX;
          } else {
            delete values.customerInteractionType;
          }
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      readOnly={readOnly}
                      type="text"
                      name={nameof<BonusRuleFormModel>("ruleName")}
                      label="Name"
                      variant="standard"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(14),
                }}
              >
                <ImsPaperHead text={t("bonusRuleCreate.titleDetails")} />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        readOnly={readOnly}
                        label={t("bonusRuleCreate.kindOfBonusRule")}
                        name={nameof<BonusRuleFormModel>("type")}
                        value={values.type}
                        options={typeOptions}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={12} style={{ marginBottom: theme.spacing(3) }}>
                      <b>{t("bonusRuleCreate.bonusPointsCalculation")}</b>
                    </Grid>
                    {values.type === BonusRuleType.TRANSACTION && (
                      <Grid item md={3}>
                        <FormSelect
                          readOnly={readOnly}
                          options={valueTypeOrRoundingModeOptions}
                          name={nameof<BonusRuleFormModel>("valueTypeOrRoundingMode")}
                          label={t("bonusRuleCreate.pointsPerTransaction")}
                          fullWidth
                        />
                      </Grid>
                    )}
                    {values.type === BonusRuleType.INTERACTION && (
                      <Grid item md={3}>
                        <FormSelect
                          readOnly={readOnly}
                          name={nameof<BonusRuleFormModel>("customerInteractionType")}
                          label="Nutzeraktivität"
                          options={interactionTypeOptions}
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item container md={1} justifyContent="center" alignItems="center">
                      {t("bonusRuleCreate.results")}
                    </Grid>
                    <Grid item md={3}>
                      <FormInput
                        readOnly={readOnly}
                        type="number"
                        name={nameof<BonusRuleFormModel>("value")}
                        fullWidth
                        label={t("bonusRuleCreate.numberOfPoints")}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={5} />
                    {values.customerInteractionType === CustomerInteractionType.CUSTOM && (
                      <>
                        <Grid item md={3} style={{ marginTop: theme.spacing(3) }}>
                          <FormInput
                            readOnly={readOnly}
                            type="text"
                            name={nameof<BonusRuleFormModel>("customInteraction")}
                            label="ID der Aktivität"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={9} />
                      </>
                    )}
                    <Grid item md={12} style={{ marginBottom: theme.spacing(3) }} />
                    <Grid item md={3}>
                      <FormInput
                        readOnly={readOnly}
                        type="number"
                        name={nameof<BonusRuleFormModel>("daysToDelay")}
                        label={t("bonusRuleCreate.delayedCreditingInDays")}
                        variant="outlined"
                        fullWidth
                        helperText="Optional"
                      />
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={3}>
                      <FormInput
                        readOnly={readOnly}
                        type="number"
                        name={nameof<BonusRuleFormModel>("budget")}
                        label={t("bonusRuleCreate.totalBonusPointsBudget")}
                        helperText="Optional"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={5} />
                    <Grid item md={12} style={{ marginBottom: theme.spacing(3) }} />
                    {values.type === BonusRuleType.TRANSACTION && (
                      <>
                        <Grid item md={3}>
                          <FormSelect
                            readOnly={readOnly}
                            options={purchaseTotalConditionOptions}
                            name={nameof<BonusRuleFormModel>("transactionSumType")}
                            label={t("bonusRuleCreate.transactionValue")}
                            fullWidth
                            helperText="Optional"
                          />
                        </Grid>
                        <Grid item md={1} />
                        <Grid item md={3}>
                          <FormInput
                            readOnly={readOnly}
                            type="number"
                            name={nameof<BonusRuleFormModel>("transactionSumValue")}
                            label={t("bonusRuleCreate.transactionTotal")}
                            variant="outlined"
                            fullWidth
                            helperText="Optional"
                          />
                        </Grid>
                        <Grid item md={5} />
                      </>
                    )}
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(14),
                }}
              >
                <ImsPaperHead text={t("bonusRuleCreate.titleRuleRuntime")} />
                <Grid container spacing={4}>
                  <Grid item md={4}>
                    <FormDateTime
                      readOnly={readOnly}
                      name={nameof<BonusRuleFormModel>("validFrom")}
                      label={t("bonusRuleCreate.startDate")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}>
                    <FormDateTime
                      readOnly={isViewMode}
                      name={nameof<BonusRuleFormModel>("validTill")}
                      label={t("bonusRuleCreate.endDate")}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4}></Grid>
                  <Grid item md={12}>
                    <b>{t("bonusRuleCreate.executionFrequency")}</b>
                  </Grid>
                  <Grid item md={3}>
                    <FormInput
                      readOnly={readOnly}
                      type="number"
                      name={nameof<BonusRuleFormModel>("frequency")}
                      label={t("bonusRuleCreate.executionPerCustomer")}
                      helperText="Optional"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={1} />
                  <Grid item md={3}>
                    <FormSelect
                      readOnly={readOnly}
                      options={frequencyOptions}
                      name={nameof<BonusRuleFormModel>("frequencyMode")}
                      label={t("bonusRuleCreate.pro")}
                      fullWidth
                      // helperText="Optional"
                    />
                  </Grid>
                  <Grid item md={1} />
                  <Grid item md={3}>
                    <FormInput
                      readOnly={readOnly}
                      type="number"
                      name={nameof<BonusRuleFormModel>("frequencyMax")}
                      label={t("bonusRuleCreate.maximumPerBonusRule")}
                      helperText="Optional"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={1} />
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text={t("bonusRuleCreate.bonusRuleFilter")} />
                <Grid container spacing={4}>
                  <Grid container spacing={2} item>
                    {values.type === BonusRuleType.TRANSACTION && (
                      <>
                        <Grid item md={2}>
                          {t("bonusRuleCreate.operationalUnit")}
                        </Grid>
                        <Grid item md={6}>
                          <FormSelect
                            readOnly={isViewMode}
                            name={nameof<BonusRuleFormModel>("unitNumbers")}
                            label={t("bonusRuleCreate.placeholderOperationalUnit")}
                            fullWidth
                            multiple
                            hierarchyLogic
                            initialValues={props.bonusRule ? props.bonusRule.unitNumbers : []}
                            options={getOperationalUnitOptions(undefined, props.operationalUnits, theme, true, false)}
                          />
                        </Grid>
                        <Grid item md={4} />
                      </>
                    )}

                    <Grid item md={6}>
                      <FormRadioButton
                        readOnly={readOnly}
                        label={t("bonusRuleCreate.customerFilterType")}
                        name={nameof<BonusRuleFormModel>("customerFilterType")}
                        value={values.customerFilterType}
                        options={customerFilterOptions}
                        row
                      />
                    </Grid>
                    <Grid item md={6} />

                    {values.customerFilterType === CustomerFilterType.CIRCLES ? (
                      <>
                        <Grid item md={2}>
                          {t("bonusRuleCreate.circles")}
                        </Grid>
                        <Grid item md={6}>
                          <FormSelect
                            readOnly={isViewMode}
                            fullWidth
                            multiple
                            name={nameof<BonusRuleFormModel>("bonusCircleNumbers")}
                            label={t("bonusRuleCreate.placeholderCircles")}
                            options={circleOptions}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item md={2}>
                          {t("bonusRuleCreate.segments")}
                        </Grid>
                        <Grid item md={6}>
                          <SegmentSelect
                            readOnly={isViewMode}
                            label={t("bonusRuleCreate.segments")}
                            name={nameof<BonusRuleFormModel>("segments")}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item md={4} />
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text={t("bonusRuleFilter.itemFilter")} />
                <Grid container spacing={4}>
                  {values.type === BonusRuleType.TRANSACTION && <Filter bonusRule={values} viewMode={isViewMode} />}
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text={t("bonusRuleCreate.titleBonusRuleDescription")} />
                <Grid container spacing={4}>
                  <Grid container spacing={2} item>
                    <Grid item md={12}>
                      <FormInput
                        readOnly={readOnly}
                        multiline
                        type="textarea"
                        name={nameof<BonusRuleFormModel>("promotionText")}
                        label={t("bonusRuleCreate.description")}
                        helperText={t("bonusRuleCreate.descriptionInfo")}
                        variant="outlined"
                        fullWidth
                        rows={5}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => navigate(UrlPathBonus)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("bonusRuleCreate.buttonCancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isViewMode || submitting}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("bonusRuleCreate.bonusRule")}{" "}
                  {values.ruleNumber ? t("bonusRuleCreate.buttonSave") : t("bonusRuleCreate.buttonCreate")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
};

const mapStateToProps = (state: IStore) => ({
  circles: state.circles.circles,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkAddBonusRule, thunkAddBonusRuleWithFile, thunkUpdateBonusRule }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusRuleForm);
