import { Search } from "@mui/icons-material";
import { Button, Grid, Paper, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkInteractionSearch } from "../../store/actions/InteractionActions";
import { IStore } from "../../store/IStore";
import { FormInput } from "../atoms/FormInput";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { nameof } from "../atoms/Utils";

export interface SegmentSearchCondition {
  name?: string;
}

interface SegmentSearchProps {
  setSearchCondition: React.Dispatch<React.SetStateAction<SegmentSearchCondition>>;
}

const SegmentSearch = (props: SegmentSearchProps & ThunkProps) => {
  const theme = useTheme();
  const { setSearchCondition } = props;

  const onSubmit = (segment: SegmentSearchCondition) => {
    setSearchCondition({
      name: segment.name,
    });
  };

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: "",
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Paper
              style={{
                padding: theme.spacing(3, 3, 6, 3),
                margin: theme.spacing(6, 0, 3, 0),
              }}
            >
              <ImsPaperHead text={t("segmentSearch.title")} />
              <Grid container spacing={4}>
                <Grid container spacing={2} item>
                  <Grid container spacing={2} item>
                    <Grid item sm={12}>
                      <b>{t("segmentSearch.name")}</b>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <FormInput
                        type="text"
                        fullWidth={true}
                        name={nameof<SegmentSearchCondition>("name")}
                        label={t("segmentSearch.placeHolderName")}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={5} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid container style={{ marginBottom: theme.spacing(3) }} justifyContent="flex-end">
              <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                <Search />
                {t("segmentSearch.buttonSearch")}
              </Button>
            </Grid>
          </form>
        );
      }}
    />
  );
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkInteractionSearch }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(SegmentSearch);
