import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { SegmentDto } from "../../store/models/segments/SegmentDto";
import SegmentTableMenu from "./SegmentTableMenu";
import { useCallback, useEffect, useState } from "react";
import { Api } from "../../api/Api";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { FileDownload } from "@mui/icons-material";
import { COLOR_GRAY_3 } from "../atoms/ImsMaterialTheme";
import { SegmentSearchCondition } from "./SegmentSearch";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "../../store/actions/NotificationActions";
import i18n from "../../i18n";
import { SegmentStats } from "../../store/models/segments/SegmentStats";
import { formatGermanDate } from "../atoms/Utils";
import { YellowDot } from "../atoms/YellowDot";
import { RedDot } from "../atoms/RedDot";
import { GreenDot } from "../atoms/GreenDot";

interface SegmentTableProps {
  searchCondition: SegmentSearchCondition;
}

const DEFAULT_SORT_FIELD = "name";
const DEFAULT_SORT_DIRECTION = "asc";

const SegmentTable = (props: SegmentTableProps & ThunkProps) => {
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortField, setSortField] = useState(DEFAULT_SORT_FIELD);
  const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
  const { t } = useTranslation();
  const [segments, setSegments] = useState<SegmentDto[]>([]);

  const loadSegments = useCallback(() => {
    setLoading(true);
    Api.getPaginatedSegments(props.searchCondition.name ?? "", page + 1, itemsPerPage, sortField, sortDirection)
      .then((data) => {
        setSegments(data.segments);
        setTotal(data.total);
      })
      .catch((e) => {
        setSegments([]);
        props.thunkCreateErrorNotification(t("segmentsPage.messages.errorLoadingSegments"), e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props, t, page, itemsPerPage, sortField, sortDirection]);

  useEffect(() => {
    loadSegments();
  }, [props, t, page, itemsPerPage, sortField, sortDirection, loadSegments]);

  const rows = segments.map((segment) => ({
    name: segment.name,
    externalId: segment.externalSegmentId,
    membershipStats: {
      count: segment.membershipCount,
      share: segment.membershipPercentage,
      lastUpdate: segment.membershipLastUpdate,
    } as SegmentStats,
    availability: segment.segmentAssignmentTasks?.[0]?.status,
    defaultSegment: segment.defaultSegment,
  }));

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("segmentsPage.segmentsTable.colName"),
      disableColumnMenu: true,
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <div>
          <div>
            <b>{params.row.name}</b>
          </div>
          <div style={{ color: "gray" }}>{params.row.externalId}</div>
        </div>
      ),
    },
    {
      field: "membershipStats",
      headerName: t("segmentsPage.segmentsTable.colMembershipStats"),
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 300,
      sortable: false,
      editable: false,
      renderCell: (params) => MembershipStats(params.row.membershipStats),
    },
    {
      field: "availability",
      headerName: t("segmentsPage.segmentsTable.colAvailability"),
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => showStatusLight(params.value as string),
    },
    {
      field: "defaultSegment",
      headerName: t("segmentsPage.segmentsTable.colDefault"),
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 250,
      editable: false,
      renderCell: (params) => showBooleanLight(params.value as boolean),
    },
    {
      field: "membershipStatsLastUpdate",
      headerName: t("segmentsPage.segmentsTable.colMembershipUpdated"),
      disableColumnMenu: true,
      flex: 1,
      maxWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => MembershipLastUpdate(params.row.membershipStats),
    },
    {
      field: "export",
      headerName: t("segmentsPage.segmentsTable.colExport"),
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      maxWidth: 100,
      editable: false,
      renderCell: (params) => {
        const handleExport = async () => {
          try {
            await Api.triggerExportSegmentMemberships(params.row.externalId);
            props.thunkCreateSuccessNotification(t("segmentsPage.messages.exportTriggered"));
          } catch (error) {
            props.thunkCreateErrorNotification("Failed to export segment memberships", error);
          }
        };
        return <FileDownload onClick={handleExport} style={{ cursor: "pointer" }} />;
      },
    },
    {
      field: "action",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      width: 50,
      renderCell: (params) => {
        const segment = {
          name: params.row.name as string,
          externalSegmentId: params.row.externalId as string,
          defaultSegment: params.row.defaultSegment as boolean,
        } as SegmentDto;
        return <SegmentTableMenu segment={segment} onSegmentDeleted={loadSegments} />;
      },
    },
  ];

  const resetSort = () => {
    setSortField(DEFAULT_SORT_FIELD);
    setSortDirection(DEFAULT_SORT_DIRECTION);
  };

  // Handle sorting changes
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    if (newSortModel.length > 0) {
      const { field, sort } = newSortModel[0];
      setSortField(field); // Set sort field
      setSortDirection(sort ?? DEFAULT_SORT_DIRECTION);
    } else {
      resetSort();
    }
  };

  const showBooleanLight = (isOn: boolean) => {
    return isOn ? <GreenDot /> : <RedDot />;
  };

  const showStatusLight = (status?: string) => {
    switch (status) {
      case "UPLOADING":
      case "PROCESSING":
        return (
          <>
            <YellowDot style={{ marginRight: 10 }} /> {mapStatusLabel(status)}
          </>
        );
      case "UPLOAD_FAILED":
      case "PARTIAL_FAILURE":
      case "FAILED":
        return (
          <>
            <RedDot style={{ marginRight: 10 }} /> {mapStatusLabel(status)}
          </>
        );
      case "SUCCEEDED":
        return (
          <>
            <GreenDot style={{ marginRight: 10 }} /> {mapStatusLabel(status)}
          </>
        );
      default:
        return "";
    }
  };

  const mapStatusLabel = (status?: string) => {
    switch (status) {
      case "UPLOADING":
        return i18n.t("segmentsPage.segmentTaskStatus.inprogress");
      case "UPLOAD_FAILED":
        return i18n.t("segmentsPage.segmentTaskStatus.failed");
      case "PROCESSING":
        return i18n.t("segmentsPage.segmentTaskStatus.inprogress");
      case "SUCCEEDED":
        return i18n.t("segmentsPage.segmentTaskStatus.completed");
      case "PARTIAL_FAILURE":
        return i18n.t("segmentsPage.segmentTaskStatus.failed");
      case "FAILED":
        return i18n.t("segmentsPage.segmentTaskStatus.failed");
      default:
        return "";
    }
  };

  return (
    <Grid>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          boxShadow: 2,
          borderRadius: 2,
          marginBottom: 4,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: COLOR_GRAY_3, // Light gray background
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold", // Makes header text bold
          },
        }}
      >
        <DataGrid
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          getRowId={(row) => row.externalId}
          rows={rows}
          rowCount={total}
          loading={isLoading}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          rowsPerPageOptions={[10, 50, 100]}
          pagination
          page={page}
          pageSize={itemsPerPage}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setItemsPerPage(newPageSize)}
          columns={columns}
          autoHeight
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("common.pagination.rowsPerPage"),
            },
          }}
        />
      </Box>
    </Grid>
  );
};

const MembershipStats = (stats: SegmentStats) => {
  const { t } = useTranslation();
  if (stats.count === undefined) {
    return <div> {t("segmentsPage.segmentsTable.notAvailable")} </div>;
  }
  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
      <div><b>{stats.count ?? ""}</b></div>
      <div>{stats.share ? t("segmentsPage.segmentsTable.percentOfTotalCustomer", { percent: stats.share }) : ""}</div>
    </div>
  );
};

const MembershipLastUpdate = (stats: SegmentStats) => {
  const { t } = useTranslation();
  if (stats.lastUpdate === undefined) {
    return <div>{t("segmentsPage.segmentsTable.notAvailable")}</div>;
  }
  return <div>{formatGermanDate(stats.lastUpdate)}</div>;
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      thunkCreateErrorNotification,
      thunkCreateSuccessNotification,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(SegmentTable);
