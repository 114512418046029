import { PersonAdd } from "@mui/icons-material";
import { Button, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { UrlPathSegment, UrlPathSegmentBuilderCsv } from "../../../api/url";
import MenuLayout from "../../app/MenuLayout";
import ImsPaperHead from "../../atoms/ImsPaperHead";

const SegmentsBuilder = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [datasourceSelection, setDatasourceSelection] = useState<string | null>(searchParams.get("selection"));
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    // Update URL when selection changes
    if (datasourceSelection) {
      setSearchParams({ selection: datasourceSelection });
    } else {
      setSearchParams({});
    }
  }, [datasourceSelection, setSearchParams]);

  return (
    <div>
      <MenuLayout headline={t("segmentBuilder.title")}>
        <Paper style={{ padding: theme.spacing(3), marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
          <ImsPaperHead text={t("segmentBuilder.header")} />
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: theme.spacing(2), color: 'black', fontWeight: 'bold' }}>
                {t("segmentBuilder.selectionInstruction")}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item sm={4}>
                <Paper
                  onClick={() => setDatasourceSelection("fileUpload")}
                  style={{
                    padding: theme.spacing(2),
                    backgroundColor: datasourceSelection === "fileUpload" ? "#75f0cf" : "#f5f5f5",
                    cursor: "pointer",
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    paddingRight: theme.spacing(2),
                    borderRight: `1px solid ${theme.palette.divider}`,
                    marginRight: theme.spacing(2)
                  }}>
                    <PersonAdd />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography variant="h6">{t("segmentBuilder.fileUpload")}</Typography>
                    <Typography variant="body2">
                      {t("segmentBuilder.fileUploadDescription").split("\n\n")[0]}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item sm={4}>
                <Paper
                  onClick={() => setDatasourceSelection("aiSegments")}
                  style={{
                    padding: theme.spacing(2),
                    backgroundColor: datasourceSelection === "aiSegments" ? "#75f0cf" : "#f5f5f5",
                    cursor: "pointer",
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    paddingRight: theme.spacing(2),
                    borderRight: `1px solid ${theme.palette.divider}`,
                    marginRight: theme.spacing(2)
                  }}>
                    <PersonAdd />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography variant="h6">
                      {t("segmentBuilder.aiSegments")} ({t("common.comingSoon")})
                    </Typography>
                    <Typography variant="body2">
                      {t("segmentBuilder.aiSegmentsDescription")}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item sm={4}>
                <Paper
                  onClick={() => setDatasourceSelection("easyFilters")}
                  style={{
                    padding: theme.spacing(2),
                    backgroundColor: datasourceSelection === "easyFilters" ? "#75f0cf" : "#f5f5f5",
                    cursor: "pointer",
                    display: "flex",
                    height: "100%",
                  }}
                >
                  <div style={{ 
                    display: "flex", 
                    alignItems: "center",
                    paddingRight: theme.spacing(2),
                    borderRight: `1px solid ${theme.palette.divider}`,
                    marginRight: theme.spacing(2)
                  }}>
                    <PersonAdd />
                  </div>
                  <div style={{ flex: 1 }}>
                    <Typography variant="h6">
                      {t("segmentBuilder.easyFilters")} ({t("common.comingSoon")})
                    </Typography>
                    <Typography variant="body2">
                      {t("segmentBuilder.easyFiltersDescription")}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: theme.spacing(2) }}>
          <Button color="secondary" variant="contained" component={Link} to={UrlPathSegment} style={{ minWidth: 150 }}>
            {t("common.cancel")}
          </Button>
          <Button color="primary" variant="contained" component={Link} to={UrlPathSegmentBuilderCsv}
                  disabled={datasourceSelection !== "fileUpload"} style={{ minWidth: 150 }}>
            {t("common.next")}
          </Button>
        </div>
      </MenuLayout>
    </div>
  );
};

export default SegmentsBuilder; 